import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Button from "../Button";

import EnrollmentTable from "./EnrollmentTable";

const HeldEnrollments = ({
  enrollments,
  selectedView,
  fetchAddNote,
  fetchEnrollments,
  fetchMoreEnrollments,
  fetchCancelEnrollments,
  resubmitEnrollment,
  fetchUpdateEnrollmentName,
  adminUser,
  pageHeld,
  limit,
  hasNextHeld,
  totalHeld,
}) => {
  const searchCriteria = { status: "hold" };
  const [disabled, setDisabled] = useState(false);

  useEffect(async () => {
    if (selectedView == "Held Enrollments") {
      await fetchEnrollments(searchCriteria, true);
    }
  }, [selectedView]);

  return (
    <div style={{ marginTop: "30px" }}>
      <EnrollmentTable
        isHold={true}
        adminUser={adminUser}
        searchCriteria={searchCriteria}
        enrollments={enrollments}
        fetchAddNote={fetchAddNote}
        fetchEnrollments={fetchEnrollments}
        fetchCancelEnrollments={fetchCancelEnrollments}
        resubmitEnrollment={resubmitEnrollment}
        fetchUpdateEnrollmentName={fetchUpdateEnrollmentName}
      />

      {hasNextHeld && (
        <Button
          onClick={async () => {
            setDisabled(true);
            await fetchMoreEnrollments(searchCriteria, true);
            setDisabled(false);
          }}
          color="primary"
          variant="contained"
          style={{
            marginRight: 20,
            marginTop: 10,
          }}
          disabled={disabled}
        >
          Load More
        </Button>
      )}

      {enrollments.length > 0 && enrollments.length !== totalHeld ? (
        <div style={{ float: "right", marginTop: "10px" }}>
          Downloaded {(pageHeld + 1) * limit} of {totalHeld} enrollments
        </div>
      ) : (
        enrollments.length > 0 && (
          <div style={{ float: "right", marginTop: "10px" }}>
            Downloaded all {totalHeld} enrollments
          </div>
        )
      )}
    </div>
  );
};

HeldEnrollments.propTypes = {
  enrollments: PropTypes.array,
};

export default HeldEnrollments;
