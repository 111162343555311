import sha256 from "sha256";
import * as constants from "../../constants";
import fetch, { genericFetch } from "../util/api-ajax";
import { fetchMemberDeposit, refreshMemberProduct, requestMeterInfo, fetchMemberSalesChannel } from "./search";
import * as status from "./pageStatus"

function requestUpdateAutopay() {
  return {
    type: constants.REQUEST_CHANGE_TO_ACCOUNT,
    isFetching: true,
  };
}

function requestUpdateStatementDelivery() {
  return {
    type: constants.REQUEST_CHANGE_TO_ACCOUNT,
    isFetching: true,
  };
}

function requestApplyRemoveMemberProduct() {
  return {
    type: constants.REQUEST_CHANGE_TO_ACCOUNT,
    isFetching: true,
  };
}

function requestChangeToAccount() {
  return {
    type: constants.REQUEST_CHANGE_TO_ACCOUNT,
    isFetching: true,
  };
}

function setIsFetching(isFetching) {
  return {
    type: constants.REQUEST_CHANGE_TO_ACCOUNT,
    isFetching,
  };
}

function requestSetTaxExemption() {
  return {
    type: constants.REQUEST_SET_TAX_EXEMPTION,
    isFetching: true,
  };
}

function requestAddFunds() {
  return {
    type: constants.REQUEST_ADD_FUNDS,
    isFetching: true,
  };
}

function receiveUserUpdate(response, property, value) {
  const newResponse = response;

  if (response.success === true) {
    newResponse.msg = `Success: ${property} updated to ${value}`;
  } else {
    newResponse.msg = response.error || `Error - Updating ${property}`;
  }
  return {
    type: constants.RECEIVE_USER_UPDATE,
    isFetching: false,
    response: newResponse,
    property,
    value,
  };
}

function noteUpdatedRequest() {
  return {
    type: constants.MEMBER_NOTE_UPDATE_REQUEST,
  };
}

function noteCreatedRequest() {
  return {
    type: constants.MEMBER_NOTE_CREATE_REQUEST,
  };
}

function noteUpdatedSuccess(payload) {
  return {
    type: constants.MEMBER_NOTE_UPDATE_SUCCESS,
    payload,
  };
}

function noteCreatedSuccess(payload) {
  return {
    type: constants.MEMBER_NOTE_CREATE_SUCCESS,
    payload,
  };
}

function noteCreatedFailure(payload) {
  return {
    type: constants.MEMBER_NOTE_CREATE_FAILURE,
    payload,
  };
}

function noteUpdatedFailure(payload) {
  return {
    type: constants.MEMBER_NOTE_UPDATE_FAILURE,
    payload,
  };
}
function passwordResetRequest() {
  return {
    type: constants.MEMBER_RESET_REQUEST,
  };
}

function passwordResetSuccess(payload) {
  return {
    type: constants.MEMBER_RESET_SUCCESS,
    payload,
  };
}

function passwordResetfailed(payload) {
  return {
    type: constants.MEMBER_RESET_FAIL,
    payload,
  };
}

function requestLMITypes() {
  return {
    type: constants.REQUEST_LMI_TYPES,
    isFetching: true,
  };
}
function receiveLMITypes(data) {
  return {
    type: constants.RECEIVE_LMI_TYPES,
    isFetching: false,
    data,
  };
}

export function fetchLMITypes() {
  return (dispatch) => {
    dispatch(requestLMITypes);
    return fetch(constants.GET_LMI_TYPES).then((data) => {
      dispatch(receiveLMITypes(data.lmi_types));
    });
  };
}

export function noteUpdate(ID, authorID, authorEmail, body, tags) {
  const opts = { ID, authorID, authorEmail, body, tags };
  return (dispatch) => {
    dispatch(noteUpdatedRequest());
    return fetch(constants.MEMBER_NOTE_UPDATE_URL, opts)
      .then((res) => {
        dispatch(noteUpdatedSuccess(res));
      })
      .catch((error) => {
        dispatch(noteUpdatedFailure(error));
      });
  };
}

export function noteCreate(authorID, authorEmail, userID, memberID, body, tags) {
  const opts = { authorID, authorEmail, userID, memberID, body, tags };
  return (dispatch) => {
    dispatch(noteCreatedRequest());
    return fetch(constants.MEMBER_NOTE_CREATE_URL, opts)
      .then((res) => {
        dispatch(noteCreatedSuccess(res));
      })
      .catch((error) => {
        dispatch(noteCreatedFailure(error));
      });
  };
}

export function clearSelectedMember() {
  return (dispatch) =>
    dispatch({
      type: constants.CLEAR_SELECTED_MEMBER,
    });
}

function receiveMemberUpdate(response, property, value, secondaryObject = {}) {
  if (
    typeof property === "object" &&
    !Array.isArray(property) &&
    property !== null
  ) {
    response.msg = Object.keys(property).join(" & ");
    response.msg = `Success: ${response.msg} were updated`;
  } else {
    const msgValue = secondaryObject.title ? secondaryObject.title : value;
    const msgProperty = secondaryObject.property
      ? `${property} ${secondaryObject.property}`
      : property;
    if (response.success === true) {
      response.msg = `Success: ${msgProperty} updated to ${msgValue}`;
    } else {
      response.msg = response.error || `Error - Updating ${msgProperty}`;
    }
  }

  return {
    type: constants.RECEIVE_MEMBER_UPDATE,
    isFetching: false,
    response,
    property,
    value,
  };
}

function receiveExemptionUpdate(response, property, value, exemptionCode) {
  if (response.success === true) {
    response.msg = `Success: tax exemptoin updated to ${exemptionCode}`;
  } else {
    response.msg = response.error || `Error - Updating tax exemption`;
  }
  return {
    type: constants.RECEIVE_MEMBER_UPDATE,
    isFetching: false,
    response,
    property,
    value,
  };
}

function receiveBillingAddFunds(response, property, value) {
  if (response.account_balance) {
    response.msg = `Success: ${property} updated to ${value}`;
    response.success = true;
  } else {
    response.msg = response.error || `Error - Updating ${property}`;
  }
  return {
    type: constants.RECEIVE_ADD_FUNDS_CONF,
    isFetching: false,
    response,
    property: "account_balance",
    value: response.account_balance,
  };
}

function receiveBillingOneTimePayment(response) {
  return {
    type: constants.REQUEST_ADD_FUNDS,
    isFetching: false,
    response,
  };
}

function receiveAddPayment(response) {
  return {
    type: constants.ADD_PAYMENT_CHARGES,
    data: response.payment,
  };
}

function receiveApplyRefundConf(response, property, value) {
  const newMsg = response;

  if (response.success === true) {
    response.msg = `Success: ${property} updated to ${value}`;
    response.success = true;
  } else {
    response.msg = response.error || `Error - Updating ${property}`;
  }
  return {
    type: constants.RECEIVE_APPLY_REFUND_CONF,
    isFetching: false,
    response,
    property: "account_balance",
    value: response.new_balance,
  };
}

function receiveAddMeterConf(response, meterIdentifier) {
  const newMsg = response;

  if (response.success === true) {
    response.msg = `Success: New meter ${meterIdentifier} added`;
    response.success = true;
  } else {
    response.msg =
      response.error || `Error - Updating adding meter ${meterIdentifier}`;
  }

  return {
    type: constants.RECEIVE_ADD_METER_CONF,
    isFetching: false,
    response,
  };
}

function receiveCloseAccountConf(confMsg) {
  const newMsg = confMsg;
  let property;
  let value;

  if (confMsg.success === true) {
    newMsg.msg = "Success - Deactivate Recharge";
    newMsg.success = true;
    property = "active";
    value = false;
  } else {
    newMsg.msg = confMsg.error || "Error - Deactivate Recharge";
  }
  return {
    type: constants.RECEIVE_CLOSE_ACCOUNT_CONF,
    isFetching: false,
    response: newMsg,
    property,
    value,
  };
}

function receiveActivateAccount(data) {
  const newMsg = data;
  let property;
  let value;
  if (data.success === true) {
    newMsg.msg = "Success - Activate Recharge";
    newMsg.success = true;
    property = "active";
    value = true;
  } else {
    newMsg.msg = data.error || "Error - Activate Recharge";
  }
  return {
    type: constants.RECEIVE_BILLING_ACTIVATE,
    isFetching: false,
    response: newMsg,
    property,
    value,
  };
}

export function addAdditionalMeter(
  meterIdentifier,
  requestedStartDate,
  token,
  enrollType,
  member
) {
  return (dispatch) => {
    dispatch(requestChangeToAccount());

    //   TODO: MeterLook up should change ESI namespace to MeterIdentifier
    return fetch(constants.METER_LOOKUP, { esiID: meterIdentifier })
      .then((meterLookupResponse) => {
        let address = {
          street_address:
            meterLookupResponse.address.line1 +
            " " +
            meterLookupResponse.address.line2,
          city: meterLookupResponse.city,
          state: meterLookupResponse.state,
          country: meterLookupResponse.country,
          postal_code: meterLookupResponse.postal_code,
        };

        let formattedBirthday = member.get("birthday").split("T")[0];

        if (formattedBirthday.includes("/")) {
          let birthday = formattedBirthday.split("/");
          let year = birthday[2];
          let month =
            parseInt(birthday[0], 10) < 10 ? "0" + birthday[0] : birthday[0];
          let day =
            parseInt(birthday[1], 10) < 10 ? "0" + birthday[1] : birthday[1];
          formattedBirthday = year + "-" + month + "-" + day;
        }

        return fetch(constants.MEMBER_CREATE, {
          userID: member.get("userID"),
          member_agreement: true,
          first_name: member.get("first_name"),
          last_name: member.get("last_name"),
          email: member.get("email"),
          address,
          recharge_dollars: "49",
          birthday: formattedBirthday,
        });
      })
      .then((memberCreateResponse) => {
        return fetch(constants.PAYMENT_ENROLL, {
          memberID: memberCreateResponse.memberID,
          emailAddress: member.get("email"),
        });
      })
      .then((paymentEnrollResponse) => {
        return fetch(constants.PAYMENT_ADD_CARD, {
          memberID: paymentEnrollResponse.result.memberID,
          stripeToken: token.id,
          is_default: true,
        });
      })
      .then((paymentAddCardResponse) => {
        return fetch(constants.BILLING_ADDFUNDS, {
          hashID: sha256(JSON.stringify(paymentAddCardResponse)),
          amount: "49",
          initial_payment: true,
          memberID: paymentAddCardResponse.result.memberID,
        });
      })
      .then((billingAddFundsResponse) => {
        let params = {
          meterIdentifier: meterIdentifier,
          enrollMode: enrollType,
          memberID: billingAddFundsResponse.memberID,
        };
        if (requestedStartDate !== "") {
          params.requestedStartDate = requestedStartDate;
        }
        return fetch(constants.ADD_METER, params);
      })
      .then((addMeterResponse) => {
        dispatch(receiveAddMeterConf(addMeterResponse, meterIdentifier));
      })
      .catch((error) => {
        dispatch(receiveAddMeterConf(error));
      });
  };
}

export function userUpdate(userId, property, value) {
  return (dispatch) => {
    dispatch(requestChangeToAccount());
    return fetch(constants.USER_UPDATE, {
      userID: userId,
      [property]: value,
    })
      .then((response) =>
        dispatch(receiveUserUpdate(response, property, value))
      )
      .catch((response) => dispatch(receiveUserUpdate(response)));
  };
}

export function memberUpdate(memberId, property, value, secondaryObject) {
  return (dispatch) => {
    dispatch(requestChangeToAccount());
    return fetch(constants.MEMBER_UPDATE, {
      memberID: memberId,
      [property]: value,
    })
      .then((msg) =>
        dispatch(receiveMemberUpdate(msg, property, value, secondaryObject))
      )
      .catch((msg) => dispatch(receiveMemberUpdate(msg)));
  };
}

export function archiveMember(memberId, archive) {
  return (dispatch) => {
    dispatch(requestChangeToAccount());
    return fetch(constants.MEMBER_ARCHIVE, {
      memberID: memberId,
      archive: archive,
    })
      .then((msg) =>
        dispatch(receiveMemberUpdate(msg, "archive", archive))
      )
      .catch((msg) => dispatch(receiveMemberUpdate(msg)));
  };
}

export function meterUpdate(meterID, property, value, moreProps) {
  const payload = { ID: meterID, [property]: value, ...moreProps };
  if (property == "is_cancelled") {
    payload["set_is_cancelled"] = true;
  }

  return (dispatch) => {
    dispatch(requestChangeToAccount());
    return fetch(constants.METER_UPDATE, payload)
      .then((msg) => dispatch(receiveMemberUpdate(msg, property, value)))
      .catch((msg) => dispatch(receiveMemberUpdate(msg)));
  };
}

export function csmeterUpdate(meterID, property, value, moreProps) {
  const payload = { id: meterID, [property]: value, ...moreProps };
  return (dispatch) => {
    if (moreProps && moreProps.error) {
      dispatch(receiveMemberUpdate(moreProps))
    } else {
      dispatch(requestChangeToAccount());
      return fetch(constants.CS_METER_UPDATE, payload)
        .then((msg) => dispatch(receiveMemberUpdate(msg, property, value)))
        .catch((msg) => dispatch(receiveMemberUpdate(msg)));
    };
  }
}

export function createSalesChannelMember(memberId, salesChannel, enrollmentType, enrollmentId) {
  return (dispatch) => {
    dispatch(requestChangeToAccount());
    return fetch(constants.SALES_CHANNEL_MEMBER_CREATE, {
      member_id: memberId,
      channel_id: salesChannel,
      enrollment_type: enrollmentType,
      enrollment_id: enrollmentId
    })
      .then((res) => {
        dispatch(fetchMemberSalesChannel(memberId));
        dispatch(receiveMemberUpdate({ success: true }, { salesChannel }))
      })
      .catch(error => {
        dispatch(receiveMemberUpdate(error))
      })
  };
}

export function csmeterBodyUpdate(meterID, body, memberID, moreProps) {
  const payload = { id: meterID, ...body, ...moreProps };
  return (dispatch) => {
    if (moreProps && moreProps.error) {
      dispatch(receiveMemberUpdate(moreProps))
    } else {
      dispatch(requestChangeToAccount());
      dispatch(requestMeterInfo(memberID))
      return fetch(constants.CS_METER_UPDATE, payload)
        .then((msg) => dispatch(receiveMemberUpdate(msg, body, null)))
        .catch((msg) => dispatch(receiveMemberUpdate(msg)));
    };
  }
}

export function changeExemptionCode(meters, meterID, exemptionCode) {
  // build new meter list
  for (let i = 0; i < meters.size; i++) {
    if (meters.get(i).get("ID") === meterID) {
      let metadata = meters
        .get(i)
        .get("meta_data")
        .set("tax_exemption_code", exemptionCode);
      meters = meters.set(i, meters.get(i).set("meta_data", metadata));
    }
  }
  return (dispatch) => {
    dispatch(requestSetTaxExemption());
    return fetch(constants.METER_SET_TAX_EXEMPTION, {
      meterID: meterID,
      exemption_code: exemptionCode,
    })
      .then((msg) =>
        dispatch(receiveExemptionUpdate(msg, "meters", meters, exemptionCode))
      )
      .catch((msg) =>
        dispatch(receiveExemptionUpdate(msg, "meters", meters, exemptionCode))
      );
  };
}

export function applyAddFunds(memberId, property, value) {
  return (dispatch) => {
    dispatch(requestAddFunds());
    const payload = {
      start: new Date().toISOString(),
      member_id: memberId,
      [property]: value,
      initial_payment: false,
      paymentSubType: "manual_payment",
    };
    payload.hashID = sha256(JSON.stringify(payload));

    return fetch(constants.BILLING_ADDFUNDS, payload)
      .then((response) => {
        dispatch(receiveBillingAddFunds(response, property, value));
      })
      .catch((response) => {
        dispatch(receiveBillingAddFunds(response));
      });
  };
}

export function makeOneTimePayment(memberId, property, value, payload) {
  return (dispatch) => {
    dispatch(requestAddFunds());
    return fetch(constants.BILLING_ONE_TIME_PAYMENT, payload)
      .then((response) => {
        dispatch(receiveBillingOneTimePayment(response, property, value));
      })
      .catch((response) => {
        dispatch(receiveBillingAddFunds(response));
      });
  };
}

export function makeOneTimePaymentICheck(memberId, property, value, payload) {
  return (dispatch) => {
    dispatch(requestAddFunds());
    return fetch(constants.BILLING_ONE_TIME_PAYMENT_ICHECK, payload)
      .then((response) => {
        dispatch(receiveBillingOneTimePayment(response, property, value));
      })
      .catch((response) => {
        dispatch(receiveBillingAddFunds(response));
      });
  };
}

export function makeOneTimePaymentStripe(memberId, property, value, payload) {
  return (dispatch) => {
    dispatch(requestAddFunds());
    return fetch(constants.BILLING_ONE_TIME_PAYMENT_STRIPE, payload)
      .then((response) => {
        dispatch(receiveBillingOneTimePayment(response, property, value));
      })
      .catch((response) => {
        dispatch(receiveBillingAddFunds(response));
      });
  };
}

export function addPayment(
  memberId,
  amount,
  chargeID,
  fundingSource,
  referenceID,
  paymentType
) {
  return (dispatch) => {
    dispatch(setIsFetching(true));
    const payload = {
      amount: parseFloat(amount).toFixed(2),
      payment_type: "manual_payment",
      status: "captured",
      member_id: memberId,
      charge_id: chargeID,
      meta_data: {
        funding_source: fundingSource,
        type: paymentType,
      },
      reference_id: referenceID,
    };
    payload.hashID = sha256(JSON.stringify(payload));

    return fetch(constants.PAYMENT_SAVE_PAYMENT, payload)
      .then((response) => {
        dispatch(receiveAddPayment(response));
      })
      .catch((response) => {
        dispatch(receiveAddPayment(response));
      })
      .finally(() => {
        dispatch(setIsFetching(false));
      });
  };
}

export function applyRefund(memberId, property, value) {
  return (dispatch) => {
    dispatch(requestChangeToAccount());
    return fetch(constants.BILLING_REFUND, {
      memberID: memberId,
      [property]: value,
    })
      .then((response) =>
        dispatch(receiveApplyRefundConf(response, property, value))
      )
      .catch((response) => dispatch(receiveApplyRefundConf(response)));
  };
}

export function closeAccount(memberId) {
  return (dispatch) => {
    dispatch(requestChangeToAccount());
    return fetch(constants.BILLING_ACCOUNT, { memberID: memberId })
      .then(() => {
        return fetch(constants.BILLING_DEACTIVATE, {
          memberID: memberId,
        }).then((msg) => dispatch(receiveCloseAccountConf(msg)));
      })
      .catch((response) => dispatch(receiveCloseAccountConf(response)));
  };
}

export function refundAndCloseAccount(memberId) {
  return (dispatch) => {
    dispatch(requestChangeToAccount());
    return fetch(constants.BILLING_ACCOUNT, { memberID: memberId })
      .then(() => {
        return fetch(constants.BILLING_REFUND, {
          memberID: memberId,
          deactivate_account: true,
        }).then((msg) => dispatch(receiveCloseAccountConf(msg)));
      })
      .catch((err) => {
        dispatch(receiveCloseAccountConf(err));
      });
  };
}

export function activateAccount(memberId) {
  return (dispatch) => {
    dispatch(requestChangeToAccount());
    return fetch(constants.BILLING_ACTIVATE, { memberID: memberId })
      .then((data) => {
        dispatch(receiveActivateAccount(data));
      })
      .catch((err) => {
        dispatch(receiveActivateAccount(err));
      });
  };
}

export function clearConfMsg() {
  return {
    type: constants.CLEAR_MEMBER_CONF_MSG,
  };
}

export function hideSnackBar() {
  return {
    type: constants.HIDE_SNACKBAR,
  };
}

export const resetPassword = (email) => (dispatch) => {
  const fields = { email };
  dispatch(passwordResetRequest());
  return fetch(constants.SIGNUP_RESET_PASSWORD_URL, fields)
    .then((res) => {
      if (res.success) {
        return dispatch(passwordResetSuccess(res));
      }
      dispatch(passwordResetfailed(res));
    })
    .catch((err) => {
      dispatch(passwordResetfailed(err));
    });
};

export const activateUser = (userID) => (dispatch) => {
  return fetch(constants.USER_ACTIVATE, { userID })
    .then((response) =>
      dispatch(receiveUserUpdate(response, "userIsActive", true))
    )
    .catch((response) => dispatch(receiveUserUpdate(response)));
};

export const deActivateUser = (userID) => (dispatch) => {
  return fetch(constants.USER_DEACTIVATE, { userID })
    .then((response) =>
      dispatch(receiveUserUpdate(response, "userIsActive", false))
    )
    .catch((response) => dispatch(receiveUserUpdate(response)));
};

function receiveBillingAccountData(type, response, property, value) {
  if (response.hasOwnProperty(property)) {
    response.msg = `Success: ${property} updated to ${value}`;
    response.success = true;

    return {
      type,
      isFetching: false,
      response,
      property,
      value: response[property],
    };
  } else {
    response.msg = response.error || `Error - Updating ${property}`;

    return {
      type,
      isFetching: false,
      response,
    };
  }
}

export const updateAutopay = (memberID, autopay) => (dispatch) => {
  dispatch(requestUpdateAutopay());
  return fetch(constants.BILLING_UPDATE_AUTOPAY, {
    memberID,
    autopay,
  })
    .then((response) =>
      dispatch(
        receiveBillingAccountData(
          constants.UPDATE_AUTO_PAY_CONF,
          response,
          "autopay",
          true
        )
      )
    )
    .catch((response) =>
      dispatch(
        receiveBillingAccountData(
          constants.UPDATE_AUTO_PAY_CONF,
          response,
          "autopay"
        )
      )
    );
};

export const updateAccount = (id, key, updatedData) => (dispatch) => {
  dispatch(requestUpdateAutopay());
  return fetch(constants.BILLING_UPDATE_ACCOUNT, {
    id,
    ...updatedData,
  })
    .then((response) =>
      dispatch(
        receiveBillingAccountData(
          constants.RECEIVE_MEMBER_UPDATE,
          { ...response, ...updatedData },
          key,
          updatedData
        )
      )
    )
    .catch((response) =>
      dispatch(
        receiveBillingAccountData(
          constants.RECEIVE_MEMBER_UPDATE,
          response,
          key
        )
      )
    );
};

export const updateStatementDelivery =
  (memberID, statement_delivery) => (dispatch) => {
    dispatch(requestUpdateStatementDelivery());
    return fetch(constants.BILLING_UPDATE_STATEMENT_DELIVERY, {
      memberID,
      statement_delivery,
    })
      .then((response) =>
        dispatch(
          receiveBillingAccountData(
            constants.UPDATE_STATEMENT_DELIVERY_CONF,
            response,
            "statement_delivery",
            true
          )
        )
      )
      .catch((response) =>
        dispatch(
          receiveBillingAccountData(
            constants.UPDATE_STATEMENT_DELIVERY_CONF,
            response,
            "statement_delivery"
          )
        )
      );
  };

function receiveRemoveMemberProduct(type, response, property, value) {
  if (value) {
    response.msg = `Success: ${property} updated`;
    response.success = true;
    return {
      type,
      isFetching: false,
      response,
      property,
      value: response[property],
    };
  } else {
    response.msg = response.error || `Error - Updating ${property}`;
    return {
      type,
      isFetching: false,
      response,
    };
  }
}

export const applyRemoveMemberProduct = (data) => (dispatch) => {
  dispatch(requestApplyRemoveMemberProduct());
  return fetch(constants.MEMBER_PRODUCT_UPDATE, {
    member_product: {
      ID: data.product_id,
      end_date: data.start_date.split('T')[0],
      renewal_date: data.start_date.split('T')[0],
    }
  })
    .then((response) => {
      return fetch(constants.MEMBER_NOTE_CREATE_URL, {
        authorID: data.author_id,
        authorEmail: data.author_email,
        userID: data.user_id,
        memberID: data.member_id,
        body: data.body,
      })
        .then((res) => {
          dispatch(refreshMemberProduct(data.member_id))
          dispatch(receiveRemoveMemberProduct(
            constants.MEMBER_PRODUCT_REMOVE_SUCCESS,
            response,
            "member_product",
            true
          ))
        })
        .catch((error) => {
          dispatch(receiveRemoveMemberProduct(
            constants.MEMBER_PRODUCT_REMOVE_SUCCESS,
            response,
            "member_product"
          ))
        });
    })
    .catch((response) =>
      dispatch(
        receiveRemoveMemberProduct(
          constants.MEMBER_PRODUCT_REMOVE_SUCCESS,
          response,
          "member_product"
        )
      )
    );
};

function requestCaptureDeposit() {
  return {
    type: constants.REQUEST_CAPTURE_DEPOSIT,
    isFetching: true,
  };
}

function receiveCaptureDepositConf(response) {
  const newMsg = response;

  if (response.success === true) {
    newMsg.msg = `Success: Meter Deposit Captured`;
    newMsg.success = true;
  } else {
    newMsg.msg = response.error || `Error - Unable to capture meter deposit`;
  }

  return {
    type: constants.RECEIVE_CAPTURE_METER_DEPOSIT_CONF,
    isFetching: false,
    response: newMsg,
  };
}

export function captureDeposit(
  member_id,
  meter_id,
  meter_identifier,
  amount,
  enrollment_id
) {
  const payload = {
    member_id,
    meter_id,
    meter_identifier,
    amount,
    enrollment_id,
  };
  return (dispatch) => {
    dispatch(requestCaptureDeposit());
    return fetch(constants.METER_CAPTURE_DEPOSIT, payload)
      .then((response) => {
        dispatch(fetchMemberDeposit(member_id));
        dispatch(receiveCaptureDepositConf(response));
      })
      .catch((response) => {
        dispatch(receiveCaptureDepositConf(response));
      });
  };
}

export function listMemberDocuments(memberID) {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    try {
      const response = await fetch(constants.MEMBER_LIST_DOCUMENTS_URL, { memberID });
      dispatch({ type: constants.MEMBER_LIST_DOCUMENTS, payload: response.files });
    } catch {
      dispatch({ type: constants.MEMBER_LIST_DOCUMENTS, payload: [] });
      dispatch(status.setErrorMessage("Error fetching member documents."));
    } finally {
      dispatch(status.finishProcessing());
    }
  }
}

export function uploadMemberDocument(memberID, document, category) {
  return async (dispatch) => {
    let success = false;
    dispatch(status.startProcessing());
    try {
      // Get presigned url and info to register back to the server
      const response = await fetch(
        constants.MEMBER_GET_DOCUMENT_UPLOAD_URL,
        { memberID, file_name: document.name, file_category: category },
      )
      if (!response.upload_url) {
        throw new Error("Failed to get document upload information.")
      }

      // Upload directly to url avoid passing file around
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": document.type,
          "Content-Length": document.size,
        },
        body: document,
      };

      // S3 doesn't give us a paresable response so use raw
      const file_uploaded = await genericFetch(response.upload_url, options, true);
      if (!file_uploaded || !file_uploaded.ok) {
        throw new Error("Failed to upload document.")
      }

      // Report back to DB
      const register_uploaded = await fetch(constants.MEMBER_REGISTER_DOCUMENT_UPLOAD_URL, response.report_request)
      if (!register_uploaded.success) {
        throw new Error("Failed to register uploaded document.");
      }
      dispatch(status.setSuccessMessage(document.name + " uploaded successfully."));
      success = true;

    } catch (error) {
      dispatch(status.setErrorMessage(error.message))
    } finally {
      dispatch(status.finishProcessing());
    }
    return success;
  }
}

export function updateMemberDocument(parameters) {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    try {
      await fetch(constants.MEMBER_UPDATE_DOCUMENT, parameters);
      dispatch(status.setSuccessMessage("Document updated successfully."));
    } catch {
      dispatch(status.setErrorMessage("Error updating document."));
    } finally {
      dispatch(status.finishProcessing());
    }
  }
}

export function deleteMemberDocument(memberID, fileID) {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    try {
      await fetch(constants.MEMBER_DELETE_DOCUMENT_URL, { memberID, fileID });
      dispatch(status.setSuccessMessage("Document deleted successfully."));
    } catch {
      dispatch(status.setErrorMessage("Error deleting document."));
    } finally {
      dispatch(status.finishProcessing());
    }
  }
}

export function openMemberDocument(memberID, fileID) {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    try {
      const response = await fetch(constants.MEMBER_GET_DOCUMENT_DOWNLOAD_URL, { memberID, fileID });
      window.open(response.download_url, "_blank");
    } catch {
      dispatch(status.setErrorMessage("Error getting download URL."));
    } finally {
      dispatch(status.finishProcessing());
    }
  }
}

export function getRateCodes(duns_number) {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    try {
      const response = await fetch(constants.TDSP_LIST_RATE_CODES_URL, { duns_number });
      dispatch({ type: constants.TDSP_LIST_RATE_CODES, payload: response.rate_codes });
    } catch {
      dispatch({ type: constants.TDSP_LIST_RATE_CODES, payload: [] });
      dispatch(status.setErrorMessage("Error getting rate codes."));
    } finally {
      dispatch(status.finishProcessing());
    }
  }
}

export function getNoteTags() {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    try {
      const response = await fetch(constants.MEMBER_NOTE_TAGS_URL);
      const tags = {};
      response.tags.forEach((tag) => {
        tags[tag.ID] = tag;
      });

      dispatch({ type: constants.MEMBER_NOTE_TAGS_SUCCESS, payload: { tags } });
    } catch {
      dispatch(status.setErrorMessage("Error getting note tags."));
    } finally {
      dispatch(status.finishProcessing());
    }
  }
}

export function createNoteTag({ name }) {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    try {
      await fetch(constants.MEMBER_NOTE_TAGS_CREATE_URL, { name });
      dispatch(status.setSuccessMessage("Tag created successfully."));
    } catch {
      dispatch(status.setErrorMessage("Error creating tag."));
    } finally {
      dispatch(status.finishProcessing());
    }
    await dispatch(getNoteTags());
  }
}

export function updateNoteTag({ ID, name }) {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    try {
      await fetch(constants.MEMBER_NOTE_TAGS_UPDATE_URL, { ID, name });
      dispatch(status.setSuccessMessage("Tag updated successfully."));
    } catch {
      dispatch(status.setErrorMessage("Error updating tag."));
    } finally {
      dispatch(status.finishProcessing());
    }
    await dispatch(getNoteTags());
  }
}

export function deleteNoteTag({ ID }) {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    try {
      await fetch(constants.MEMBER_NOTE_TAGS_DELETE_URL, { ID });
      dispatch(status.setSuccessMessage("Tag deleted successfully."));
    } catch {
      dispatch(status.setErrorMessage("Error deleting tag."));
    } finally {
      dispatch(status.finishProcessing());
    }
    await dispatch(getNoteTags());
  }
}

export const redirectToCustomer = (userID, memberID) => (dispatch) => {
  dispatch(status.startProcessing());
  return fetch(constants.USER_ACCESS_URL, { userID })
    .then((response) => {
      dispatch(status.finishProcessing());
      const accessToken = response.access_token
      const redirectCustomerPortal = `${process.env.REACT_APP_ACCOUNT_APP_URL}/redirect?member_id=${memberID}&access_token=${accessToken}`
      window.open(redirectCustomerPortal, '_blank', 'noopener,noreferrer')
    })
    .catch((response) => {
      dispatch(status.finishProcessing());
    });
};
