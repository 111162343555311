import { createSelector } from "reselect";

const devices = () => (state) => state.devices;

const selectDevicesContainer = () =>
  createSelector(devices(), (devicesData) => {
    return devicesData;
  });

export default selectDevicesContainer;
