import React, { useState, useEffect } from "react";
import { Link } from "react-router";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import Button from "../Button";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControl, FormLabel, FormControlLabel } from "@material-ui/core/";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import { noselect } from "../../styles/common.css";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import { validateEmail, validatePhone } from "../../util/validations";

import { PhoneNumberMask, phoneUnmaskedValue } from "../../util/masks";

const useStyles = makeStyles({
  root: {
    width: "600px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "none",
  },
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  disableButton: {
    cursor: "default",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  label: {
    marginBottom: "10px",
  },
});

const StyledTextField = ({
  id,
  label,
  value,
  maskProps,
  setFormField,
  errors,
  disabled,
}) => {
  const onChange = (e) => {
    let val = e.target.value;
    if (maskProps?.setMaskedValue) {
      maskProps.setMaskedValue(val);
    }
    if (maskProps?.unmask) {
      val = maskProps.unmask(val);
    }
    setFormField(id, val);
  };

  const inputProps = maskProps?.mask
    ? { inputComponent: maskProps.mask }
    : undefined;
  return (
    <TextField
      label={label}
      size="small"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      InputProps={inputProps}
      id={id}
      style={{ marginTop: "10px", width: "100%" }}
      onChange={onChange}
      value={value}
      type="text"
      error={errors[id]}
      helperText={errors[id]}
      disabled={disabled}
    />
  );
};

const UpdateEnrollmentDetails = (props) => {
  const classes = useStyles();
  const {
    details,
    onResubmit,
    closePopup,
    onEdit,
    onAddNote,
    setCancelDialogOpen,
    setCancelStatus,
    adminUser,
    onVerification,
  } = props;

  const [isWaiveDeposit, setIsWaiveDeposit] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isReleased, setIsReleased] = useState(false);
  const [isIDVerified, setIsIDVerified] = useState(false);

  const [verificationMethod, setVerificationMethod] = useState(
    details.preferred_verification_method
  );

  const [releaseNote, setReleaseNote] = useState(null);
  const [error, setError] = useState(null);
  const [editParams, setEditParams] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [note, setNote] = useState(null);

  const [formErrors, setFormErrors] = useState({});

  const originalEnrollmentInfo = {
    first_name: details.first_name,
    last_name: details.last_name,
    email: details.email,
    phone: phoneUnmaskedValue(details.phone),
    name_key: details.name_key,
    company: details.company,
    meter_identifier1: details.meter_identifier,
    meter_identifier2: details.meter_identifier2,
  };
  const [enrollmentInfo, setEnrollmentInfo] = useState(originalEnrollmentInfo);
  // Display value to avoid multiple re-renders on changes to masked field
  const [phoneMasked, setPhoneMasked] = useState(originalEnrollmentInfo.phone);

  const validations = {
    first_name: (value) => {
      return value ? "" : "First Name is required";
    },
    last_name: (value) => {
      return value ? "" : "Last Name is required";
    },
    meter_identifier1: (value) => {
      return value ? "" : "Meter Identifier 1 is required";
    },
    email: (value) => {
      if (!value) {
        return "Email is required";
      }
      return validateEmail(value) ? "" : "Invalid Email";
    },
    phone: (value) => {
      if (!value) {
        return "Phone is required";
      }
      return validatePhone(value) ? "" : "Invalid Phone";
    },
  };

  // Reset form fields and initialize errors
  const resetForm = () => {
    setEnrollmentInfo(originalEnrollmentInfo);
    const errors = {};
    for (const [k, v] of Object.entries(validations)) {
      errors[k] = v(originalEnrollmentInfo[k]);
    }
    // Keep masked display value up to date
    setPhoneMasked(originalEnrollmentInfo.phone);
    setFormErrors(errors);
  };

  const setFormField = (key, value) => {
    if (validations[key]) {
      setFormErrors({ ...formErrors, [key]: validations[key](value) });
    }
    setEnrollmentInfo({ ...enrollmentInfo, [key]: value });
  };

  const isFormValid = () => {
    for (const val of Object.values(formErrors)) {
      if (val) {
        return false;
      }
    }
    return true;
  };

  const submitForm = () => {
    if (isReleased && !releaseNote) {
      setError("Please enter a release note.");
      return;
    }
    if (isReleased) {
      onResubmit(
        details.id,
        false,
        false,
        false,
        false,
        isReleased,
        releaseNote
      );
    } else {
      onResubmit(
        details.id,
        isWaiveDeposit,
        isCompleted,
        isVerified,
        isIDVerified,
        isReleased,
        releaseNote
      );
    }
    setError(null);
    setReleaseNote(null);
    closePopup();
  };

  const editForm = () => {
    onEdit({
      id: details.id,
      ...enrollmentInfo,
      phone: `+${enrollmentInfo.phone}`, // append leading + to validated phone number for consistency
      verification_method: verificationMethod,
    });

    setEditParams(!editParams);
  };

  const addNote = () => {
    onAddNote({
      id: details.id,
      note: note,
    });
  };

  const sendVerification = () => {
    onVerification({
      id: details.id,
    });
  };

  const handleClose = () => {
    closePopup();
  };

  useEffect(() => {
    setError(null);
    setReleaseNote(null);
    setDocuments(
      details.customer_uploaded_documents
        ? details.customer_uploaded_documents
        : []
    );
  }, [details]);

  const isProcessed = details.enrollment_status == "processed";
  return (
    <div className={classes.root}>
      <Card className={classes.card} variant="outlined">
        <div className={`${classes.cardTitle} ${noselect}`}>
          <span>Enrollment Detail Screen</span>
          <CloseIcon onClick={() => closePopup()} />
        </div>
        <FormControl className={classes.form}>
          <Grid container style={{ padding: "10px" }}>
            <Grid item md={12} xs={12}>
              <FormLabel className={classes.label}>
                <Typography color="primary" variant="h6" display="inline">
                  Enrollment ID: &nbsp;
                </Typography>
                <Typography color="secondary" variant="h6" display="inline">
                  {details.id}
                </Typography>
              </FormLabel>
            </Grid>
            <Grid style={{ marginTop: "10px" }} item md={6} xs={6}>
              <FormLabel className={classes.label}>
                Address: {details.address.line1} {details.address.line2}{" "}
                {details.address.city}, {details.address.state}
              </FormLabel>
            </Grid>
            <Grid style={{ marginTop: "10px" }} item md={6} xs={6}>
              <FormLabel className={classes.label}>
                Birthdate: {details.birthdate}
              </FormLabel>
            </Grid>
            <Grid style={{ marginTop: "10px" }} item md={6} xs={6}>
              <FormLabel className={classes.label}>
                Start Date: {details.requested_start_date}
              </FormLabel>
            </Grid>
            <Grid style={{ marginTop: "10px" }} item md={6} xs={6}>
              <FormLabel className={classes.label}>
                Status: {details.enrollment_status}
              </FormLabel>
            </Grid>
            <Grid style={{ marginTop: "10px" }} item md={6} xs={6}>
              <FormLabel className={classes.label}>
                Verification: {details.verification_status}
              </FormLabel>
            </Grid>
            <Grid style={{ marginTop: "10px" }} item md={6} xs={6}>
              <FormLabel className={classes.label}>
                Product: {details.product_code}
              </FormLabel>
            </Grid>
            <Grid style={{ marginTop: "10px" }} item md={6} xs={6}>
              <FormLabel className={classes.label}>
                Promo: {details.promo_code}
              </FormLabel>
            </Grid>
            <Grid style={{ marginTop: "10px" }} item md={6} xs={6}>
              <FormLabel className={classes.label}>
                Commodity Rate: {details.commodity_rate}
              </FormLabel>
            </Grid>
            <Grid style={{ marginTop: "10px" }} item md={6} xs={6}>
              <FormLabel className={classes.label}>
                Enroll Type: {details.enrollment_type}
              </FormLabel>
            </Grid>
            <Grid style={{ marginTop: "10px" }} item md={6} xs={6}>
              <FormLabel className={classes.label}>
                Deposit: ${details.deposit_amount}
              </FormLabel>
            </Grid>
            <Grid style={{ marginTop: "10px" }} item md={6} xs={6}>
              <FormLabel className={classes.label}>
                Paid Deposit: {details.deposit_paid ? "Paid" : "Not Paid"}
              </FormLabel>
            </Grid>
            <Grid style={{ marginTop: "10px" }} item md={6} xs={6}>
              <FormLabel className={classes.label}>
                Completed Date:{" "}
                {details.completed_date
                  ? moment(details.completed_date).format("MMM DD, YYYY")
                  : ""}
              </FormLabel>
            </Grid>
            <Grid style={{ marginTop: "10px" }} item md={6} xs={6}>
              <FormLabel className={classes.label}>
                Referral:{" "}
                {details.referral_type &&
                details.referral_code &&
                details.referral_name
                  ? `${details.referral_type}-${details.referral_code}-${details.referral_name}`
                  : ""}
              </FormLabel>
            </Grid>
            <Grid style={{ marginTop: "10px" }} item md={6} xs={6}>
              <FormLabel className={classes.label}>
                Member ID:{" "}
                {details.member_id ? (
                  <Link to={`/members/${details.member_id}/details`}>
                    {details.member_id}
                  </Link>
                ) : (
                  details.member_id
                )}
              </FormLabel>
            </Grid>
          </Grid>
          <Grid container style={{ padding: "10px", paddingTop: "0px" }}>
            <Grid
              style={{ paddingTop: "10px", paddingRight: "10px" }}
              item
              md={6}
              xs={6}
            >
              <StyledTextField
                label="First Name"
                id="first_name"
                errors={formErrors}
                setFormField={setFormField}
                value={enrollmentInfo.first_name}
                disabled={editParams}
              />
            </Grid>
            <Grid style={{ paddingTop: "10px" }} item md={6} xs={6}>
              <StyledTextField
                label="Last Name"
                id="last_name"
                errors={formErrors}
                setFormField={setFormField}
                value={enrollmentInfo.last_name}
                disabled={editParams}
              />
            </Grid>
            <Grid
              style={{ paddingTop: "10px", paddingRight: "10px" }}
              item
              md={6}
              xs={6}
            >
              <StyledTextField
                label="Meter Identifier 1"
                id="meter_identifier1"
                errors={formErrors}
                setFormField={setFormField}
                value={enrollmentInfo.meter_identifier1}
                disabled={editParams}
              />
            </Grid>
            <Grid style={{ paddingTop: "10px" }} item md={6} xs={6}>
              <StyledTextField
                label="Meter Identifier 2"
                id="meter_identifier2"
                errors={formErrors}
                setFormField={setFormField}
                value={enrollmentInfo.meter_identifier2}
                disabled={editParams}
              />
            </Grid>
            <Grid
              style={{ paddingTop: "10px", paddingRight: "10px" }}
              item
              md={6}
              xs={6}
            >
              <StyledTextField
                label="Company"
                id="company"
                errors={formErrors}
                setFormField={setFormField}
                value={enrollmentInfo.company}
                disabled={editParams}
              />
            </Grid>
            <Grid style={{ paddingTop: "10px" }} item md={6} xs={6}>
              <StyledTextField
                label="Name Key"
                id="name_key"
                errors={formErrors}
                setFormField={setFormField}
                value={enrollmentInfo.name_key}
              />
            </Grid>
            <Grid
              style={{ paddingTop: "10px", paddingRight: "10px" }}
              item
              md={6}
              xs={6}
            >
              <StyledTextField
                label="Email"
                id="email"
                errors={formErrors}
                setFormField={setFormField}
                value={enrollmentInfo.email}
                disabled={editParams}
              />
            </Grid>
            <Grid style={{ paddingTop: "10px" }} item md={6} xs={6}>
              <StyledTextField
                label="Phone Number"
                id="phone"
                maskProps={{
                  mask: PhoneNumberMask,
                  unmask: phoneUnmaskedValue,
                  setMaskedValue: setPhoneMasked,
                }}
                errors={formErrors}
                setFormField={setFormField}
                value={phoneMasked}
                disabled={editParams}
              />
            </Grid>
            <Grid
              style={{ paddingTop: "10px", paddingRight: "10px" }}
              item
              md={6}
              xs={6}
            >
              <TextField
                label="Preferred Verification Method"
                type="text"
                size="small"
                color="primary"
                value={verificationMethod}
                style={{ marginTop: "10px", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                onChange={(e) => setVerificationMethod(e.target.value)}
                disabled={editParams}
                select
              >
                <MenuItem value={"sms"}>SMS</MenuItem>
                <MenuItem value={"email"}>Email</MenuItem>
              </TextField>
            </Grid>
            {adminUser &&
              adminUser.permissions &&
              adminUser.permissions.isEnrollmentManager && (
                <Grid
                  style={{ marginTop: "10px", textAlign: "right" }}
                  item
                  md={12}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    style={{}}
                    disabled={() => {
                      return details.enrollment_status === "processed";
                    }}
                    onClick={async () => {
                      resetForm();
                      setEditParams(!editParams);
                    }}
                  >
                    {editParams ? "Edit" : "Cancel"}
                  </Button>
                  {!editParams && (
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{
                        visibility: editParams && "hidden",
                        marginLeft: "10px",
                      }}
                      disabled={!isFormValid()}
                      onClick={async () => await editForm()}
                    >
                      Save
                    </Button>
                  )}
                </Grid>
              )}
          </Grid>
          {details.exceptions &&
            details.exceptions.list &&
            details.exceptions.list.length > 0 && (
              <Grid container style={{ padding: "10px", paddingTop: "0px" }}>
                <Grid item md={12} xs={12}>
                  <b>Exceptions</b>
                </Grid>
                <Grid item md={12} xs={12}>
                  {details.exceptions.list.map((item, index) => (
                    <Chip
                      key={index}
                      label={item}
                      color="secondary"
                      variant="outlined"
                      style={{ margin: "5px" }}
                    />
                  ))}
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isReleased}
                        onChange={(e) => setIsReleased(e.target.checked)}
                        name="chkIsReleased"
                        disabled={details.enrollment_status !== "hold"}
                      />
                    }
                    label={`Release Enrollment`}
                    className={`${classes.toggleContainer} ${noselect}`}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  {isReleased && (
                    <Grid
                      container
                      style={{ padding: "10px", paddingTop: "0px" }}
                    >
                      <Grid item md={12} xs={12}>
                        <b>Add a note</b>
                      </Grid>
                      <Grid style={{ marginTop: "10px" }} item md={12}>
                        <TextField
                          label="Release Note"
                          size="small"
                          variant="outlined"
                          multiline
                          rows={4}
                          InputLabelProps={{ shrink: true }}
                          id="releaseNote"
                          style={{
                            marginTop: "10px",
                            width: "100%",
                            marginBottom: "10px",
                          }}
                          onChange={(e) => setReleaseNote(e.target.value)}
                          value={releaseNote}
                          type="text"
                        />
                        <Button
                          color="secondary"
                          variant="contained"
                          disabled={details.enrollment_status === "processed"}
                          onClick={async () => {
                            submitForm();
                          }}
                          style={{ marginTop: "10px" }}
                        >
                          Release
                        </Button>
                      </Grid>
                      {error && (
                        <Grid item md={12} xs={12}>
                          <div style={{ color: "red", marginTop: "10px" }}>
                            {error}
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          <Grid container style={{ padding: "10px", paddingTop: "0px" }}>
            <Grid item md={12} xs={12}>
              <b>Notes</b>
              <List className={classes.listTransaction}>
                {details.notes?.map((item, index) => (
                  <ListItem style={{ padding: "0px" }} key={index}>
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
              {!isAddingNote && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={async () => {
                    setIsAddingNote(true);
                  }}
                  disabled={isProcessed}
                >
                  Add Note
                </Button>
              )}
              {isAddingNote && (
                <Grid style={{ marginTop: "10px" }} item md={12}>
                  <TextField
                    label="Note"
                    size="small"
                    variant="outlined"
                    multiline
                    minRows={4}
                    InputLabelProps={{ shrink: true }}
                    id="note"
                    style={{
                      marginTop: "10px",
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    onChange={(e) => setNote(e.target.value)}
                    value={note}
                    type="text"
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={async () => {
                      addNote();
                      setIsAddingNote(false);
                      setNote("");
                    }}
                    style={{ marginTop: "10px" }}
                  >
                    Save
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={async () => {
                      setIsAddingNote(false);
                      setNote("");
                    }}
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                  >
                    Cancel
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>

          {details.customer_uploaded_documents &&
            details.customer_uploaded_documents.length > 0 && (
              <>
                <Grid container style={{ padding: "10px", paddingTop: "0px" }}>
                  <Grid style={{ marginBottom: "10px" }} xs={12}>
                    <b>Documents</b>
                  </Grid>
                  {details.customer_uploaded_documents.map((item, index) => (
                    <Grid item md={3}>
                      <Link
                        to={`_blank`}
                        activeStyle={{
                          textDecoration: "none",
                          color: "black",
                          position: "abosolute",
                          right: 0,
                        }}
                        component="button"
                        onClick={() => window.open(item.url, "_blank")}
                      >
                        {item.document_type} - {item.original_file_name}
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          {adminUser &&
            adminUser.permissions &&
            adminUser.permissions.isEnrollmentManager && (
              <Grid container style={{ padding: "10px", paddingTop: "10px" }}>
                <Grid item md={12} xs={12}>
                  <b>Resubmit Options</b>
                  <FormControl className={classes.form}>
                    <Grid container>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isWaiveDeposit}
                            onChange={(e) =>
                              setIsWaiveDeposit(e.target.checked)
                            }
                            name="chkWaivedDeposit"
                            disabled={
                              details.deposit_amount == 0 ||
                              details.deposit_paid ||
                              isProcessed
                            }
                          />
                        }
                        label={`Mark as Waive Deposit`}
                        className={`${classes.toggleContainer} ${noselect}`}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isCompleted}
                            onChange={(e) => setIsCompleted(e.target.checked)}
                            name="chkCompleted"
                            disabled={
                              details.enrollment_status == "completed" ||
                              details.completed_date ||
                              isProcessed
                            }
                          />
                        }
                        label={`Mark as Completed`}
                        className={`${classes.toggleContainer} ${noselect}`}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isVerified}
                            onChange={(e) => setIsVerified(e.target.checked)}
                            name="chkIsVerified"
                            disabled={
                              details.verification_status == "not_required" ||
                              isProcessed
                            }
                          />
                        }
                        label={`Mark as Verified`}
                        className={`${classes.toggleContainer} ${noselect}`}
                      />
                      {details.identity_check_status != "not_required" && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isIDVerified}
                              onChange={(e) =>
                                setIsIDVerified(e.target.checked)
                              }
                              name="chkIsIDForcePass"
                              disabled={
                                details.identity_check_status == "PASS" ||
                                details.identity_check_status == "FORCE_PASS" ||
                                isProcessed
                              }
                            />
                          }
                          label={`Mark as ID Check Pass`}
                          className={`${classes.toggleContainer} ${noselect}`}
                        />
                      )}
                    </Grid>
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={
                      details.enrollment_status === "processed" ||
                      details.enrollment_status === "expired"
                    }
                    onClick={async () => {
                      submitForm();
                    }}
                    style={{ marginRight: "10px" }}
                  >
                    Resubmit
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={
                      !(
                        details.enrollment_status === "completed" &&
                        details.verification_status === "incomplete"
                      )
                    }
                    onClick={async () => {
                      await sendVerification();
                    }}
                    style={{ marginRight: "10px" }}
                  >
                    Resend Verification Link
                  </Button>
                </Grid>
              </Grid>
            )}
          {adminUser &&
            adminUser.permissions &&
            adminUser.permissions.isEnrollmentManager && (
              <Grid
                style={{ marginTop: "10px", marginLeft: "10px" }}
                item
                md={12}
              >
                <Grid item md={12} style={{ marginBottom: "10px" }}>
                  <b>Enrollment Cancellation Options</b>
                </Grid>
                <Button
                  color="secondary"
                  variant="contained"
                  disabled={details.enrollment_status === "processed"}
                  onClick={async () => {
                    handleClose();
                    setCancelStatus("canceled");
                    setCancelDialogOpen(true);
                  }}
                  style={{ marginRight: "10px" }}
                >
                  Cancel Enrollment
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  disabled={details.enrollment_status === "processed"}
                  onClick={async () => {
                    handleClose();
                    setCancelStatus("fraud");
                    setCancelDialogOpen(true);
                  }}
                  style={{ marginRight: "10px" }}
                >
                  Mark as Fraud
                </Button>
              </Grid>
            )}
        </FormControl>
        <div
          className={classes.optionContainer}
          style={{
            borderTop: "2px solid #ddd",
            paddingBottom: "10px",
          }}
        >
          <Button
            color="secondary"
            varian="raised"
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default UpdateEnrollmentDetails;
