import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Button from "../Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MaskedInput from "react-text-mask";
import EnrollmentTable from "./EnrollmentTable";
import MenuItem from "@material-ui/core/MenuItem";

function PhoneNumberMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      mask={[
        "+",
        /[1-9]/,
        " ",
        "(",
        /\d/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      guide={false}
    />
  );
}

const Enrollments = ({
  enrollments,
  selectedView,
  fetchAddNote,
  fetchEnrollments,
  fetchMoreEnrollments,
  fetchCancelEnrollments,
  resubmitEnrollment,
  fetchUpdateEnrollmentName,
  fetchSendVerification,
  adminUser,
  page,
  limit,
  hasNext,
  total,
}) => {
  const [searchCriteria, setSearchCriteria] = useState({
    email: "",
    enrollment_id: "",
    phone_number: "",
    meter_identifier: "",
    record_age_in_days: "14",
  });
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const updateSearchCriteria = (e, prop) => {
    let { value } = e.target;
    if (prop === "phone_number") {
      const phoneCleaned = `+${e.target.value.replace(/\D/g, "")}`;
      value = ["+"].includes(phoneCleaned) ? "" : phoneCleaned;
      setSearchCriteria({ ...searchCriteria, [prop]: value });
    } else {
      setSearchCriteria({ ...searchCriteria, [prop]: e.target.value });
    }
  };

  const validate = (obj) => {
    return Object.values(obj).some((value) => {
      return (
        value !== undefined &&
        value !== null &&
        value !== "" &&
        value !== 0 &&
        value !== false &&
        !Number.isNaN(value)
      );
    });
  };

  return (
    <div style={{ marginTop: "30px" }}>
      <Grid container spacing={3} style={{ width: "100%" }}>
        <Grid item sm={2}>
          <TextField
            label="Email Address"
            type="text"
            size="small"
            color="primary"
            value={searchCriteria.email}
            style={{ width: "100%" }}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              updateSearchCriteria(e, "email");
            }}
          />
        </Grid>
        <Grid item sm={2}>
          <TextField
            label="Enrollment ID"
            type="text"
            size="small"
            color="primary"
            value={searchCriteria.enrollment_id}
            style={{ width: "100%" }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={(e) => {
              updateSearchCriteria(e, "enrollment_id");
            }}
          />
        </Grid>
        <Grid item sm={2}>
          <TextField
            label="Meter Identifier"
            type="text"
            size="small"
            color="primary"
            value={searchCriteria.meter_identifier}
            style={{ width: "100%" }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={(e) => updateSearchCriteria(e, "meter_identifier")}
          />
        </Grid>
        <Grid item sm={2}>
          <TextField
            label="Phone Number"
            type="text"
            size="small"
            color="primary"
            value={searchCriteria.phone_number}
            style={{ width: "100%" }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={(e) => updateSearchCriteria(e, "phone_number")}
            InputProps={{
              inputComponent: PhoneNumberMask,
            }}
          />
        </Grid>
        <Grid item sm={2}>
          <TextField
            label="Enrollment Age"
            type="text"
            size="small"
            color="primary"
            value={searchCriteria.record_age_in_days}
            style={{ width: "100%" }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={(e) => updateSearchCriteria(e, "record_age_in_days")}
            select
          >
            <MenuItem value="all">All</MenuItem>
            {[...Array(31).keys()]
              .filter((x) => x !== 0)
              .map((x) => (
                <MenuItem value={x.toString()}>
                  {x} {x === 1 ? "day" : "days"}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <Button
            onClick={async () => {
              setDisabled(true);
              const criteria = { ...searchCriteria };
              if (criteria.record_age_in_days === "all") {
                delete criteria.record_age_in_days;
                if (validate(criteria)) {
                  setError(null);
                  await fetchEnrollments(criteria);
                } else {
                  setError(
                    "When Enrollment age is All, at least other value have to be selected"
                  );
                }
              } else {
                setError(null);
                await fetchEnrollments(criteria);
              }
              setDisabled(false);
            }}
            color="primary"
            disabled={disabled}
            variant="contained"
          >
            Search
          </Button>
        </Grid>
        {error && (
          <Grid item sm={12}>
            <div style={{ color: "red" }}>{error}</div>
          </Grid>
        )}
      </Grid>
      <br />

      <EnrollmentTable
        isHold={false}
        adminUser={adminUser}
        searchCriteria={searchCriteria}
        enrollments={enrollments}
        fetchAddNote={fetchAddNote}
        fetchEnrollments={fetchEnrollments}
        fetchCancelEnrollments={fetchCancelEnrollments}
        resubmitEnrollment={resubmitEnrollment}
        fetchSendVerification={fetchSendVerification}
        fetchUpdateEnrollmentName={fetchUpdateEnrollmentName}
      />

      {hasNext && (
        <Button
          onClick={async () => {
            setDisabled(true);
            await fetchMoreEnrollments(searchCriteria);
            setDisabled(false);
          }}
          color="primary"
          variant="contained"
          style={{
            marginRight: 20,
            marginTop: 10,
          }}
          disabled={disabled}
        >
          Load More
        </Button>
      )}

      {enrollments.length > 0 && enrollments.length !== total ? (
        <div style={{ float: "right", marginTop: "10px" }}>
          Downloaded {(page + 1) * limit} of {total} enrollments
        </div>
      ) : (
        enrollments.length > 0 && (
          <div style={{ float: "right", marginTop: "10px" }}>
            Downloaded all {total} enrollments
          </div>
        )
      )}
    </div>
  );
};

Enrollments.propTypes = {
  enrollments: PropTypes.array,
};

export default Enrollments;
