import React from "react";

const columns = [
  {
    Header: "ID",
    accessor: "id",
    show: false,
  },
  {
    Header: "Title",
    accessor: "title",
    Cell: (row) => (
      <div
        style={{
          minHeight: "100px",
          whiteSpace: "break-spaces",
          overflowY: "scroll",
        }}
      >
        {row.value}
      </div>
    ),
  },
  {
    Header: "description",
    accessor: "description",
    Cell: (row) => (
      <div
        style={{
          minHeight: "100px",
          whiteSpace: "break-spaces",
          overflowY: "scroll",
        }}
      >
        {row.value}
      </div>
    ),
  },
  {
    Header: "Agent",
    accessor: "agent",
  },
  {
    Header: "Access",
    accessor: "access",
  },
];

export default columns;
