import PropTypes from "prop-types";
import React, { useEffect } from "react";
import DevicesTable from "./DevicesTable";
import InterventionsTable from "./InterventionsTable";

const Devices = ({
  params,
  devices,
  interventions,
  listMemberDevices,
  selectDeviceInterventions,
  resetSelectedDeviceInterventions,
}) => {
  const memberID = params?.memberId;
  useEffect(() => {
    // do; while here, fetch devices and for every minute after
    listMemberDevices({ memberID: memberID });

    const interval = setInterval(() => {
      listMemberDevices({ memberID: memberID });
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, [memberID]);

  useEffect(() => {
    // clean up interventions on unmount
    return () => {
      resetSelectedDeviceInterventions();
    };
  }, []);

  return (
    <div>
      <h4>Devices</h4>
      <DevicesTable
        devices={devices}
        selectDeviceInterventions={selectDeviceInterventions}
      />
      {interventions && interventions?.length > 0 && (
        <InterventionsTable
          interventions={interventions}
          closeDialog={() => resetSelectedDeviceInterventions()}
        />
      )}
    </div>
  );
};

Devices.propTypes = {
  params: PropTypes.shape({
    memberId: PropTypes.any,
  }),
  devices: PropTypes.array,
  listMemberDevices: PropTypes.func,
  selectDeviceInterventions: PropTypes.func,
  resetSelectedDeviceInterventions: PropTypes.func,
};

export default Devices;
