import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Devices from "../components/Devices";
import selectDevicesContainer from "../selectors/devices";
import * as actions from "../actions/devices";

const MemberDevicesContainer = (props) => {
  return <Devices {...props} />;
};

const mapStateToProps = selectDevicesContainer();

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(actions, dispatch),
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberDevicesContainer);
