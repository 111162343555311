import PropTypes from "prop-types";
import React, { useState } from "react";
import { useStyles } from "./styles";
import {
  confirmationError,
  confirmationSuccess,
} from "../../styles/common.css";
import Snackbar from "../Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import EnrollmentTabs from "./EnrollmentTabs";
import SearchEnrollments from "./SearchEnrollments";
import HeldEnrollments from "./HeldEnrollments";

const Enrollments = (props) => {
  const classes = useStyles();
  const {
    enrollments,
    heldEnrollments,
    fetchEnrollments,
    fetchMoreEnrollments,
    fetchAddNote,
    fetchCancelEnrollments,
    resubmitEnrollment,
    fetchUpdateEnrollmentName,
    fetchSendVerification,
    closeSnackbar,
    showSnackBar,
    successMessage,
    errorMessage,
    isProcessing,
    adminUser,
    page,
    pageHeld,
    limit,
    hasNext,
    hasNextHeld,
    total,
    totalHeld,
  } = props;

  const [selectedView, setSelectedView] = useState("Search Enrollments");
  return (
    <div className={classes.root}>
      <Snackbar
        id="message-id"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showSnackBar}
        onClose={closeSnackbar}
        className={successMessage ? confirmationSuccess : confirmationError}
        message={successMessage || errorMessage}
      />
      <Backdrop className={classes.backdrop} open={isProcessing}>
        <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
      </Backdrop>
      <h1 id="page-title">Enrollments</h1>
      <EnrollmentTabs setSelectedView={setSelectedView}>
        <SearchEnrollments
          adminUser={adminUser}
          selectedView={selectedView}
          enrollments={enrollments}
          fetchAddNote={fetchAddNote}
          fetchEnrollments={fetchEnrollments}
          fetchMoreEnrollments={fetchMoreEnrollments}
          fetchCancelEnrollments={fetchCancelEnrollments}
          resubmitEnrollment={resubmitEnrollment}
          fetchUpdateEnrollmentName={fetchUpdateEnrollmentName}
          fetchSendVerification={fetchSendVerification}
          page={page}
          limit={limit}
          hasNext={hasNext}
          total={total}
        />
        <HeldEnrollments
          adminUser={adminUser}
          selectedView={selectedView}
          enrollments={heldEnrollments}
          fetchAddNote={fetchAddNote}
          fetchEnrollments={fetchEnrollments}
          fetchMoreEnrollments={fetchMoreEnrollments}
          fetchCancelEnrollments={fetchCancelEnrollments}
          resubmitEnrollment={resubmitEnrollment}
          fetchUpdateEnrollmentName={fetchUpdateEnrollmentName}
          fetchSendVerification={fetchSendVerification}
          pageHeld={pageHeld}
          limit={limit}
          hasNextHeld={hasNextHeld}
          totalHeld={totalHeld}
        />
      </EnrollmentTabs>
      <Backdrop className={classes.backdrop} open={isProcessing}>
        <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
      </Backdrop>
    </div>
  );
};

export default Enrollments;
