import * as constants from "../../constants";
import fetch from "../util/api-ajax";

function listMemberDevicesRequest() {
  return {
    type: constants.LIST_MEMBER_DEVICES_REQUEST,
  };
}

function listMemberDevicesSuccess(payload) {
  return {
    type: constants.LIST_MEMBER_DEVICES_SUCCESS,
    payload: payload,
  };
}

function listMemberDevicesFailure() {
  return {
    type: constants.LIST_MEMBER_DEVICES_FAILURE,
  };
}

// fetches member devices (excluding unlink status)
export const listMemberDevices =
  ({ memberID }) =>
  (dispatch) => {
    dispatch(listMemberDevicesRequest());
    const payload = {
      member_id: memberID,
      include_removed: true, // required
    };
    fetch(constants.LIST_MEMBER_DEVICES, payload)
      .then((res) => {
        dispatch(listMemberDevicesSuccess(res?.devices || []));
      })
      .catch((err) => {
        dispatch(listMemberDevicesFailure(err));
      });
  };

// sets intervention for view
export function selectDeviceInterventions(payload) {
  return {
    type: constants.SELECT_DEVICE_INTERVENTIONS,
    payload: payload,
  };
}

// reset intervention view
export function resetSelectedDeviceInterventions() {
  return {
    type: constants.RESET_SELECTED_DEVICE_INTERVENTIONS,
  };
}
