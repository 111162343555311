import * as constants from "../../constants";
import fetch from "../util/api-ajax";

export const createErrorMessage = (errorMessage) => ({
  type: constants.STATEMENT_OPEN_SNACKBAR,
  errorMessage,
});

export const setErrorMessage = (message) => (dispatch) => {
  dispatch(createErrorMessage(message));
};

const createSuccessMessage = (successMessage) => ({
  type: constants.STATEMENT_OPEN_SNACKBAR,
  successMessage,
});

export const setSuccessMessage = (message) => (dispatch) => {
  dispatch(createSuccessMessage(message));
};

export const closeSnackbar = () => {
  console.log('aca')
  return (dispatch) => dispatch({ type: constants.STATEMENT_CLOSE_SNACKBAR });
};

function isProcessing(payload) {
  return {
    type: constants.SET_STATEMENT_IS_PROCESSING,
    payload: payload,
  };
}

export const setSelectedStatusForFetch = (payload) => {
  return {
    type: constants.SET_SELECTED_STATEMENT_STATUS_FOR_FETCH,
    payload: payload,
  };
};

function requestStatements() {
  return {
    type: constants.REQUEST_BILLING_STATEMENTS,
  };
}

function receiveStatements(payload) {
  return {
    type: constants.RECEIVE_BILLING_STATEMENTS,
    payload: payload,
  };
}

function requestUpdateStatementStatus() {
  return {
    type: constants.REQUEST_UPDATE_STATEMENT_STATUS,
  };
}

function updateStatementStatusSuccess() {
  return {
    type: constants.UPDATE_STATEMENT_STATUS_SUCCESS,
  };
}

function updateStatementStatusFailure() {
  return {
    type: constants.UPDATE_STATEMENT_STATUS_FAILURE,
  };
}

export const updateStatementStatus =
  ({ statementIDs, status, errorReason, inReview = false }) =>
  (dispatch, getState) => {
    const store = getState();
    const selectedStatusForFetch = store.statements.selectedStatusForFetch;
    // accountID will be undefined when on member statement page
    const accountID = store.selectedMember.get("accountID");

    dispatch(requestUpdateStatementStatus);
    fetch(constants.BILLING_UPDATE_STATEMENT_STATUS, {
      statementIDs,
      status,
      errorReason,
    })
      .then((res) => {
        if (!res.success) {
          // error handled in catch
          throw new Error("failed to update statement(s)");
        }
        dispatch(setSuccessMessage("Statement(s) updated"));
        dispatch(updateStatementStatusSuccess);
      })
      .then(() => {
        dispatch(
          fetchBillingStatements({
            accountID,
            status: selectedStatusForFetch,
            inReview,
          })
        );
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.message));
        dispatch(updateStatementStatusFailure);
      });
  };

export const createStatement = ({ spsd, sped, isFinal, memberId, accountID }) => async (dispatch) => {
  dispatch(isProcessing(true));

  try {
    await fetch(constants.BILLING_CREATE_STATEMENT, {
      spsd,
      sped,
      isFinal,
      memberId,
    });
    dispatch(setSuccessMessage("Statement created"));
  } catch (err) {
    dispatch(setErrorMessage(err.message));
  } finally {
    dispatch(isProcessing(false));
  }

  dispatch(fetchBillingStatements({ accountID }));
};

export const fetchBillingStatements =
  ({ accountID, status, inReview = false }) =>
  (dispatch) => {
    dispatch(requestStatements());
    dispatch(isProcessing(true));
    const payload = {
      accountID: accountID,
      status: status,
      inReview: inReview
    };
    fetch(constants.BILLING_STATEMENTS, payload)
      .then((res) => {
        // Sort statements by most recent for member statements
        if (accountID != null) {
          res.statements.sort(function (a, b) {
            return new Date(b.statement_date) - new Date(a.statement_date);
          });
        }

        dispatch(receiveStatements(res));
        dispatch(isProcessing(false));
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.message));
        dispatch(isProcessing(false));
      });
  };
