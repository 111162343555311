export function timeSince(timestamp) {
  const now = new Date();
  const past = new Date(timestamp);
  const seconds = Math.floor((now - past) / 1000);

  if (seconds < 60) {
    return seconds + " second(s) ago";
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return minutes + " minute(s) ago";
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return hours + " hour(s) ago";
  } else {
    const days = Math.floor(seconds / 86400);
    return days + " day(s) ago";
  }
}

// rounded conversion of celsius to fahrenheit
export function celsiusToFahrenheit(celsius) {
  if (celsius === undefined || celsius === "") {
    return "N/A";
  }

  return `${Math.round((celsius * 9) / 5 + 32)}°F`;
}
