import * as constants from "../../constants";
import fetch from "../util/api-ajax";

export const createErrorMessage = (errorMessage) => ({
  type: constants.RENEWAL_OPEN_SNACKBAR,
  errorMessage,
});

export const setErrorMessage = (message) => (dispatch) => {
  dispatch(createErrorMessage(message));
};

const createSuccessMessage = (successMessage) => ({
  type: constants.RENEWAL_OPEN_SNACKBAR,
  successMessage,
});

export const setSuccessMessage = (message) => (dispatch) => {
  dispatch(createSuccessMessage(message));
};

export const closeSnackbar = () => {
  return (dispatch) => dispatch({ type: constants.RENEWAL_CLOSE_SNACKBAR });
};

function isProcessing(payload) {
  return {
    type: constants.SET_RENEWAL_IS_PROCESSING,
    payload: payload,
  };
}

function resetFields(payload) {
  return {
    type: constants.RENEWAL_RESET_FIELDS,
    payload: payload,
  };
}

export const receiveEnrollment = (payload) => ({
  type: constants.RECEIVE_ENROLLMENT,
  payload,
});

export const selectProductOffer = (payload) => ({
  type: constants.SELECT_RENEWAL_OFFER,
  payload,
});

function requestRenewalOffer() {
  return {
    type: constants.RENEWAL_OFFERS_REQUEST,
  };
}

function receiveRenewalOffers(payload) {
  return {
    type: constants.RECEIVE_RENEWAL_OFFERS,
    payload: payload,
  };
}

function requestEnrollRenewalOffer() {
  return {
    type: constants.REQUEST_ENROLL_RENEWAL_OFFER,
  };
}

export const submitEnrollment =
  ({ TOSYRACEFLConfirm, setters, requestedStartDate, enrollmentType }) =>
  (dispatch, getState) => {    
    dispatch(requestEnrollRenewalOffer());        
    const startDate = requestedStartDate.format("YYYY-MM-DD")
    const store = getState();
    const memberID = store.selectedMember.get("memberID");
    const { enrollment } = store.renewal;
    enrollment.formData.memberID = memberID;
    enrollment.formData.TOSYRACConfirm = TOSYRACEFLConfirm;
    enrollment.formData.EFLConfirm = TOSYRACEFLConfirm;
    enrollment.formData.requestedStartDate = startDate;
    enrollment.enrollmentType = enrollmentType;    
    enrollment.email = store.selectedMember.get("email")
    enrollment.email = store.selectedMember.get("email")
    enrollment.formData.firstName = store.selectedMember.get("first_name")
    enrollment.formData.lastName = store.selectedMember.get("last_name")
    enrollment.phone = store.selectedMember.get("phoneRaw")    
    enrollment.formData.send_enrollment_complete_notification = true
    enrollment.formData.product = {
      // correct data types
      ...enrollment.formData.product,
      term_months: enrollment.formData.product.term_months.toString(),
      max_deposit: enrollment.formData.product.max_deposit.toString(),
      start_date: startDate,
    };
    enrollment.formData.enrollmentCompletionDate = new Date().toISOString();
    enrollment.createdBy = localStorage.getItem("email");
    const payload = {
      ...enrollment,
    };    
    dispatch(isProcessing(true));
    fetch(constants.ENROLL_RENEWAL_OFFER, payload)
      .then((res) => {
        if(enrollmentType === "product_change") {
          dispatch(setSuccessMessage(`Product Change Submitted! Enrollment ID : ${res.enrollmentID}`));
        } else {
          dispatch(setSuccessMessage(`Renewal Offer Submitted! Enrollment ID : ${res.enrollmentID}`));
        }         
        setters.showSuccessModal();
        dispatch(resetFields());
        dispatch(isProcessing(false));       
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.message));
        dispatch(isProcessing(false));
      });
  };

export const fetchProductOffers =
  ({ memberID, enrollmentType, promoCode }) =>
  (dispatch, getState ) => {
    dispatch(requestRenewalOffer());
    dispatch(isProcessing(true));
    const payload = {
      memberID,
      promoCode,
    };
    let url = constants.GET_MEMBER_RENEWAL_OFFERS
    const store = getState();    
    const member = store.selectedMember;
    let meterDunsNumber
    const serviceProvider = member.get("service_provider")
    const meter = member.get("meters").first()      
    let address = {}
    const language = member.get("language")
    let tdspName = ""
    let tdspDuns = ""
    let meterUID = ""
    let meterReadCycle = ""
    if (enrollmentType === "product_change") {
      url = constants.GET_MEMBER_PRODUCT_CHANGE_OFFERS                
      if (member && member.get("service_provider") == "proton_meter") {          
        if (meter) {          
          address= meter.get("service_address")
          payload.postal_code = address.get("postalCode").substring(0, 5)
          payload.locale = member.get("language")            
          meterUID = meter.get("meterIdentifier")  
          meterDunsNumber  = meter.get("utilityDunsNumber")
        }
      } else if (serviceProvider == "eci_meter") {      
        if (meter) {                  
          address = meter.get("serviceAddress")
          payload.postal_code = address.get("postalCode").substring(0, 5)
          payload.locale = member.get("language")   
          meterDunsNumber  = meter.get("utilityDunsNumber")     
          meterReadCycle = meter.get("readCycle")    
          meterUID = meter.get("meterIdentifier")   
        }      
      }
    }
    
    fetch(url, payload)
      .then((res) => {        
        let offers = res;
        if (enrollmentType === "product_change") {       
          const tdsp = res.tdsps.find((tdsp) => tdsp.duns_number === meterDunsNumber);  
          tdspName = tdsp.name
          tdspDuns = tdsp.duns_number      
          offers.productOffers = tdsp && tdsp.offers.filter((offer) => offer.service_type === "electricity") || []
        } else {
          offers.productOffers= res.renewal_offers
        }
        dispatch(receiveRenewalOffers({ ...offers, address, language, tdspName, tdspDuns, meterUID, meterReadCycle }));
        dispatch(isProcessing(false));
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.message));
        dispatch(isProcessing(false));
      });
  };

export const fetchProtonRenewal =
  ({ meterIdentifier, enrollmentType }) =>
    ( dispatch ) => {
      dispatch(requestRenewalOffer());
      dispatch(isProcessing(true));
      const payload = {
        accountNumber: meterIdentifier,
        forPlanChange: enrollmentType === "product_change",
      };

      fetch(constants.GET_RENEWAL_URL, payload)
        .then((res) => {
          dispatch(receiveEnrollment(res));
          dispatch(isProcessing(false));
        })
        .catch((err) => {
          dispatch(setErrorMessage(err.message));
          dispatch(isProcessing(false));
          dispatch(receiveEnrollment({}));
        });
    };