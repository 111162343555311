import PropTypes from "prop-types";
import React from "react";
import ReactTable from "react-table";
import { getDeviceTableColumns } from "./columns";

const DevicesTable = ({ devices, selectDeviceInterventions }) => {
  // deconstruct meta data to top level
  const data = devices.map((device) => {
    const meta_data =
      (device?.meta_data && JSON.parse(device?.meta_data)) || {};

    const setHoldInterventions = meta_data?.set_hold_interventions || [];

    // reshape for table
    return {
      ...device,
      mode: meta_data?.mode,
      current_temperature: meta_data?.current_temperature,
      heat_set_point: meta_data?.heat_set_point,
      cold_set_point: meta_data?.cold_set_point,
      set_hold_interventions: setHoldInterventions,
    };
  });

  return (
    <ReactTable
      data={data}
      columns={getDeviceTableColumns({
        viewInterventions: selectDeviceInterventions,
      })}
      className="-striped -highlight"
      defaultPageSize={10}
      filterable
      defaultSorted={[
        {
          id: "id",
          desc: true,
        },
      ]}
    />
  );
};

DevicesTable.propTypes = {
  devices: PropTypes.array,
  selectDeviceInterventions: PropTypes.func,
};

export default DevicesTable;
