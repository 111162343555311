import * as C from "../../constants";
import ctBillingCycles from "./ct_billing_cycles.json"

const ENROLLMENT_TYPE_RENEWAL = "renewal";

const initialState = {
  isProcessing: false,
  showSnackBar: false,
  successMessage: "",
  errorMessage: "",
  offers: [],  
  enrollment: {
    enrollmentType: ENROLLMENT_TYPE_RENEWAL,
    createdBy: "admin",
    renewalDate: "",
    formData: {
      memberID: "",
      product: {},
      TOSYRACConfirm: false,
      EFLConfirm: false,
    },
  },
  enrollmentID: "",
  verificationRequired: "",
  verificationStatus: "",
  status: "",
  credit_file_data: {},
  ctBillingCycles,
};

const closeSnackBar = (state) => {
  return {
    ...state,
    showSnackBar: false,
    successMessage: "",
    errorMessage: "",
  };
};

const openSnackBar = (state, action) => {
  return {
    ...state,
    showSnackBar: true,
    ...action,
  };
};

const receiveRenewalOffers = (state, action) => {
  const { productOffers, renewal_date, ...res } = action.payload;

  return {
    ...state,
    enrollment: {
      ...state.enrollment,
      formData: { 
        ...state.enrollment.formData,        
        ...res
      },
      renewalDate: renewal_date,
    },
    offers: productOffers,
  };
};

const receiveEnrollment = (state, action) => {
  return {
    ...state,
    enrollment: action.payload,
  };
};

const selectOfferForRenewal = (state, action) => {
  return {
    ...state,
    enrollment: {
      ...state.enrollment,
      formData: {
        ...state.enrollment.formData,
        product: action.payload,
      },
    },
  };
};

const setIsProcessing = (state, action) => {
  return {
    ...state,
    isProcessing: action.payload,
  };
};

const resetFields = (state, action) => {
  return {
    ...state,
    offers: [],
    enrollment: {
      ...state.enrollment,
      requestedStartDate: "",
      formData: {
      },
    },
  };
}

export default function statements(state = initialState, action) {
  switch (action.type) {
    case C.RENEWAL_CLOSE_SNACKBAR:
      return closeSnackBar(state);
    case C.RENEWAL_OPEN_SNACKBAR:
      return openSnackBar(state, action);
    case C.SET_RENEWAL_IS_PROCESSING:
      return setIsProcessing(state, action);
    case C.RECEIVE_RENEWAL_OFFERS:
      return receiveRenewalOffers(state, action);
    case C.SELECT_RENEWAL_OFFER:
      return selectOfferForRenewal(state, action);
    case C.RECEIVE_ENROLLMENT:
      return receiveEnrollment(state, action);
    case C.RENEWAL_RESET_FIELDS:
      return resetFields(state, action);
    default:
      return state;
  }
}
