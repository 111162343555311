import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import {
  confirmationError,
  confirmationSuccess,
} from "../../styles/common.css";
import Snackbar from "../Snackbar"
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import SelectOffer from "./Offers";
import ConfirmEnrollment from "./ConfirmEnrollment";
import { Dialog } from "@material-ui/core";
import Button from "../Button"

const Enrollment = (props) => {
  const classes = useStyles();
  const {
    doFetchProductOffers,
    doFetchProtonRenewal,
    offers,
    enrollment,
    stateCode,
    doSelectProductOffer,
    doSubmitEnrollment,
    closeSnackbar,
    showSnackBar,
    successMessage,
    errorMessage,
    isProcessing,
    location,
    ctBillingCycles,
  } = props;

  const memberID = props.member.get("memberID");
  const meterIdentifier = props.member.get("meters").get(0)?.get("meterIdentifier");
  const serviceProvider = props.member.get("service_provider");
  const [openModal, setOpenModal] = useState(false);  
  const [promoCode, setPromoCode] = useState("");  
  const enrollmentType = location.pathname.includes("/product_change") ? "product_change" : "renewal";
  const title = enrollmentType === "product_change" ? "Product Change" : "Renewal";
  const validEnrollment = Boolean(enrollment.enrollmentType) || serviceProvider === "proton_meter";

  useEffect(() => {
    if (serviceProvider === "eci_meter" ) doFetchProtonRenewal({ meterIdentifier, enrollmentType });
    if (memberID && validEnrollment) doFetchProductOffers({ memberID, enrollmentType, promoCode });
  }, [memberID, enrollmentType, validEnrollment]);

  const onOfferSelected = (offer) => {
    doSelectProductOffer(offer);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  
  return (
    <div className={classes.root}>
      <Snackbar
        id="message-id"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={showSnackBar}
        onClose={closeSnackbar}
        className={successMessage ? confirmationSuccess : confirmationError}
        message={successMessage || errorMessage}
      />
      <h1 id="page-title">{title}</h1>
      {validEnrollment && enrollmentType === 'product_change' && 
        <div>
          <div className={classes.inputWrapper}>
            <label htmlFor="promoCode">
              {"Promo Code:"}
              <input
                type="text"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}         
                style={{ width: "200px", marginRight: "10px", height: "30px", fontSize: "18px" }}
              />
            </label>            
          </div>   
          <Button color="secondary" variant="contained" onClick={() => doFetchProductOffers({ memberID, enrollmentType, promoCode })}>
            {"Apply Code"}
          </Button>                 
      </div>
      }
      {validEnrollment && offers && offers.length > 0 ?  (
        <SelectOffer
          energyLevel={"2"}
          offers={offers}
          onOfferSelected={(offer) => onOfferSelected(offer)}
        />
      ) : (
        <>No offers available for this member</>
      )}
      {openModal && (
        <Dialog
          className={classes.dialog}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openModal}
        >
          <ConfirmEnrollment
            closeManualAdjustmentPopup={() => setOpenModal(false)}
            enrollment={enrollment}
            doSubmitEnrollment={doSubmitEnrollment}
            enrollmentType={enrollmentType}
            stateCode={stateCode}
            ctBillingCycles={ctBillingCycles}
          />
        </Dialog>
      )}
      <Backdrop className={classes.backdrop} open={isProcessing}>
        <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
      </Backdrop>
    </div>
  );
};

Enrollment.propTypes = {
  member: PropTypes.object.isRequired,
  doFetchProductOffers: PropTypes.func.isRequired,
  doFetchProtonRenewal: PropTypes.func.isRequired,
};

export default Enrollment;
