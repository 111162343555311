import React, { useState } from "react";

import { Text } from "../text";
import Button from "../../Button"
import PropTypes from "prop-types";
import { Flex } from "grid-styled";
import Arrow from "../../../assets/images/icons/ArrowBottom";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import {
  ContainerRateCards,
  RateCard,
  RateInfoSection,
  RateDescriptionSection,
  RateActionSection,
  EnergyUnitSection,
  OfferBasics,
  OfferDetails,
  CustomTable,
} from "./styled";

export const PopulatedRateCard = ({ offer, onOfferSelected, afterSelectionGoTo, toggleOfferDetails, isExpanded, energyLevel = "2"}) => {
  // parse rate and documents from offer
  let rate = "";
  let energyLabel = "";
  let documents = [];
  let displayDetails = {};
  displayDetails = JSON.parse(offer.display);
  const billingDetails = JSON.parse(offer.billing);
  documents = JSON.parse(offer.documents);

  // get rate and label based on energy slider value
  if (displayDetails.average_rate){
    if (energyLevel === "0") {
      rate = displayDetails.average_rate["500kWh"];
      energyLabel = "500";
    } else if (energyLevel === "2") {
      rate = displayDetails.average_rate["2000kWh"];
      energyLabel = "2000";
    } else {
      rate = displayDetails.average_rate["1000kWh"];
      energyLabel = "1000";
    }
  } else {
    rate = (billingDetails.commodity_rate * 100).toFixed(2);
  }

  const getDocLocation = (rateId, doc) => {
    // if doc location has 'template' in it, include rate_id
    if (doc.location.includes('template')) {
      return `${doc.location}&rate_id=${rateId}`
    }
    return doc.location
  }

  return (
    <RateCard key={offer.name}>
      <OfferBasics>
        <RateInfoSection>
          <Text
            color={"black"}
            textAlign="center"
            fontSize="2.5rem"
            width="100%"
            fontWeight={700}
          >
            {offer.name}
          </Text>

          {/* display price */}
          <Flex flexDirection="row" alignItems="flex-start" mt={20}>
            <Text
              color={"black"}
              textAlign="center"
              fontSize="3.5rem"
              fontWeight={700}
              lineHeight={1.2}
            >
              {rate}
            </Text>
            <Text
              color={"black"}
              textAlign="flex-start"
              fontSize="1.6rem"
              fontWeight={700}
              lineHeight={1.2}
            >
              ¢
            </Text>
            <EnergyUnitSection>
              <Text
                color={"black"}
                textAlign="bottom"
                fontSize="1.2rem"
                fontWeight={500}
                lineHeight={1.2}
              >
                per kWh
              </Text>

              {Boolean(energyLabel) && <Text
                color={"black"}
                textAlign="right"
                fontSize="1rem"
                fontWeight={500}
                lineHeight={1.2}
              >
                at {energyLabel} kWhs
              </Text>}
            </EnergyUnitSection>
          </Flex>
        </RateInfoSection>
        <RateDescriptionSection>
          <Text
            color={"black"}
            textAlign="center"
            fontSize="2.1rem"
            width="100%"
            fontWeight={500}
            lineHeight={1.2}
          >
            {offer.description}
          </Text>
        </RateDescriptionSection>
        <RateActionSection>
          <Button
            width={175}
            to={afterSelectionGoTo}
            onClick={async () => await onOfferSelected(offer)}
            variant="contained"
            color="secondary"
            disabled={onOfferSelected === undefined}
          >
            Select
          </Button>

          <Text
            color={"black"}
            mt={30}
            onClick={toggleOfferDetails}
            cursor="pointer"
          >
            Offer Details
            <Arrow color={"black"} />
          </Text>
        </RateActionSection>
      </OfferBasics>

      <OfferDetails open={isExpanded}>
        <Text color={"black"} fontWeight="650">
          {displayDetails.details_header}
        </Text>
        {displayDetails.details_bullets.map((bullet) => (
          <Text key={bullet} mb={10}>
            <li>{bullet.replace('<span>', '').replace('</span>', '')}</li>
          </Text>
        ))}
        <Text color={"black"} mt={10} mb={10} fontWeight="650">
          Offer Documents
        </Text>
        {/* display documents with links */}
        <Flex flexDirection="column" mb={30} alignItems="flex-start">
          {documents.map((doc) => (
            <Text
              color={"black"}
              mr={10}
              mb={10}
              underline
              key={doc.name}
            >
              <a
                href={getDocLocation(offer.rate_id, doc)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {doc.description}
              </a>
            </Text>
          ))}
        </Flex>
        <Text color={"black"} mb={10}>
          For detailed pricing information see the Electricity Facts
          Label. These all-in rates include your Transmission and
          Distribution Utility (TDU) charges.**
        </Text>
        { displayDetails.average_rate && <CustomTable>
            <thead>
              <tr>
                <th>Average Monthly</th>
                <th>Average Price per KWH</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>500 kWH</td>
                <td>{displayDetails.average_rate["500kWh"]}</td>
              </tr>
              <tr>
                <td>1000 kWH</td>
                <td>{displayDetails.average_rate["1000kWh"]}</td>
              </tr>
              <tr>
                <td>2000 kWH</td>
                <td>{displayDetails.average_rate["2000kWh"]}</td>
              </tr>
            </tbody>
          </CustomTable>
        }
      </OfferDetails>
    </RateCard>
  );
};

const OfferSelection = ({
  offers,
  energyLevel = "2",
  onOfferSelected,
  afterSelectionGoTo,
}) => {
  const [isExpanded, setIsExpanded] = useState({});
  const toggleOfferDetails = (offerName) => {
    const newIsExpanded = {
      ...isExpanded,
    };
    newIsExpanded[offerName] = !newIsExpanded[offerName];
    setIsExpanded(newIsExpanded);
  };

  return (
    <>
      <ContainerRateCards>
        {offers.map((offer) => <PopulatedRateCard
          offer= {offer}
          onOfferSelected={onOfferSelected}
          afterSelectionGoTo={afterSelectionGoTo}
          toggleOfferDetails={() => toggleOfferDetails(offer.name)}
          isExpanded={isExpanded[offer.name]}
          energyLevel={energyLevel}
          key={offer.name}
        />)}
      </ContainerRateCards>
    </>
  );
};
OfferSelection.propTypes = {
  offers: PropTypes.array,
  onOfferSelected: PropTypes.func,
  energyLevel: PropTypes.string,
  afterSelectionGoTo: PropTypes.string,
};

export default OfferSelection;
