import * as constants from "../../constants";

const communitySolarInitState = {
  approvals: [],
  lmiTypes: [],
  documents: [],
  approval: null
};

export function communitySolar(state = communitySolarInitState, action) {
  switch (action.type) {
    case constants.LMI_APPROVAL_LIST_APPROVALS_SUCCESS:
      return {
        ...state,
        approvals: action.payload.lmi_approval
      }
    case constants.LMI_APPROVAL_GET_LMI_TYPES_SUCCESS:
      return {
        ...state,
        lmiTypes: action.payload.lmi_types
      }
    case constants.LMI_APPROVAL_GET_LMI_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.payload.files
      }
    case constants.LMI_APPROVAL_GET_LMI_DETAILS_SUCCESS:
      return {
        ...state,
        approval: action.payload.meter
      }
    default:
      return state;
  }
}
