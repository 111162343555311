import React from "react";
import { timeSince, celsiusToFahrenheit } from "../utils";
import Button from "../../Button";

export const getDeviceTableColumns = ({ viewInterventions }) => {
  return [
    {
      Header: "ID",
      accessor: "id",
      show: false,
    },
    {
      Header: "Device ID",
      accessor: "vendor_device_id",
      Cell: (row) => row.value,
    },
    {
      Header: "Vendor",
      accessor: "vendor",
    },
    {
      Header: "Brand",
      accessor: "brand",
    },
    {
      Header: "Model",
      accessor: "model",
    },
    {
      Header: "Display Name",
      accessor: "display_name",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Mode",
      accessor: "mode",
      Cell: (row) => row.value,
    },
    {
      Header: "Current Temperature",
      accessor: "current_temperature",
      Cell: (row) => celsiusToFahrenheit(row.value),
    },
    {
      Header: "Heat Set Point",
      accessor: "heat_set_point",
      Cell: (row) => celsiusToFahrenheit(row.value),
    },
    {
      Header: "Cold Set Point",
      accessor: "cold_set_point",
      Cell: (row) => celsiusToFahrenheit(row.value),
    },
    {
      Header: "Last Updated",
      accessor: "last_updated",
      Cell: (row) => timeSince(row.value),
    },
    {
      Header: "Interventions",
      accessor: "set_hold_interventions",
      Cell: (row) => (
        <Button
          onClick={() => {
            viewInterventions(row.value);
          }}
          variant="contained"
          color="secondary"
          disabled={row.value?.length === 0} // expected to be an array
        >
          View
        </Button>
      ),
    },
  ];
};
