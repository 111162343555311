import React, { Component, Fragment } from "react";
import Moment from "react-moment";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Elements } from "react-stripe-elements";
import Button from "../components/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Modal from "react-modal";
import { Link } from "react-router";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import { FormControl } from "@material-ui/core/";
import { ClipLoader } from "react-spinners";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Snackbar from "../components/Snackbar";
import MemberDpp from "../components/members/MemberDpp";
import AccountFlags from "./AccountFlags";
import modalStyle from "../styles/modal";
import UpdateBillingAddress from "../components/members/UpdateBillingAddress";
import UpdateMeterDates from "../components/members/UpdateMeterDates";
import SetDiconnectType from "../components/members/SetDiconnectType";
import MemberProduct from "../components/members/MemberProduct";
import MemberDeposit from "../components/members/MemberDeposit";
import InputSwitch from "../components/members/InputSwitch";
import AccountBalance from "../components/members/AccountBalance";
import MemberFixReject from "../components/MemberCSMeter/MemberFixReject";
import MemberCancelled from "../components/MemberCSMeter/MemberCancelled";
import LevelBilling from "../components/members/LevelBilling";

import Dialog from "@material-ui/core/Dialog";

import { confirmationError, confirmationSuccess } from "../styles/common.css";
import {
  fetchUserIDWithFullMemberInfoNeeded,
  fetchFullMemberInfoNeeded,
} from "../actions/search";
import {
  userUpdate,
  applyAddFunds,
  applyRefund,
  closeAccount,
  memberUpdate,
  meterUpdate,
  csmeterUpdate,
  csmeterBodyUpdate,
  clearConfMsg,
  activateAccount,
  refundAndCloseAccount,
  changeExemptionCode,
  resetPassword,
  activateUser,
  deActivateUser,
  clearSelectedMember,
  updateAutopay,
  updateStatementDelivery,
  applyRemoveMemberProduct,
  updateAccount,
  makeOneTimePayment,
  makeOneTimePaymentICheck,
  makeOneTimePaymentStripe,
  archiveMember,
  captureDeposit,
  fetchLMITypes,
  redirectToCustomer,
  createSalesChannelMember,
} from "../actions/members";
import { requestTaxExcemptions, hideTaxSnackBar } from "../actions/tax";
import { requestTdsps } from "../actions/lookup";
import { fetchCardList } from "../actions/payment";
import selectTaxInfoContainer from "../selectors/tax";
import { HAS_CSA_SERVICE } from "../util/flags";

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});
const modalTypes = {
  ADD_FUND: 0,
  REFUND: 1,
  OPEN_ACCOUNT: 2,
  CLOSE_ACCOUNT: 3,
  DEACTIVATE_ACCOUNT: 4,
  CREDITS: 5,
  DEBITS: 6,
  REMOVE_START_DATE: 7,
  REMOVE_END_DATE: 8,
  ENABLE_USER: 9,
  DISABLE_USER: 10,
  SET_AUTO_PAY: 11,
  SET_STATEMENT_DELIVERY: 12,
  SET_METER_IS_CANCELLED: 13,
  OPEN_ARCHIVE: 14,
  CLOSE_ARCHIVE: 15,
};

const languageOptions = {
  en: "English",
  es: "Spanish",
};

const RelatedMembers = ({ members, meters }) => {
  return (
    <List
      style={{
        border: "1px solid lightgrey",
        marginLeft: "25px",
        marginTop: "25px",
      }}
    >
      <h4 style={{ marginLeft: "25px" }}> Related Members </h4>
      <div
        style={{
          marginLeft: "25px",
          maxHeight: "185px",
          overflowY: "auto",
        }}
      >
        <Grid alignItems="flex-start" container>
          <Grid item xs={5}>
            Meter Identifier
          </Grid>
          <Grid item xs={4}>
            START/END
          </Grid>
          <Grid item xs={3}>
            STATUS
          </Grid>
        </Grid>

        {meters &&
          members.map((m, i) => {
            const meter = meters?.find(
              (met) =>
                met.get("memberID") === m.get("memberID") ||
                met.get("member_id") === m.get("memberID")
            );
            let meterID = "";
            let meterDates = "";
            let meterStatus = "";

            if (meter === undefined) {
              meterID = "Incomplete (no meter)";
            } else if (m.get("service_provider") === "proton_meter") {
              meterID = `${meter.get("meterIdentifier")} `;
              meterDates = `${meter.get("start_date").substring(0, 10)} ${meter.get("end_date") ? "/" : ""
                } ${meter.get("end_date")?.substring(0, 10)}`;
              meterStatus = meter.get("status")?.get("status");
            } else if (m.get("service_provider") === "cs_meter") {
              meterID = `${meter.get("meter_identifier")} `;
              meterStatus = meter.get("status");
            } else if (m.get("service_provider") === "eci_meter") {
              meterID = `${meter.get("meter_identifier")} `;
              meterDates = `${meter
                .get("meterServiceStartDate")
                .substring(0, 10)} ${meter.get("meterServiceEndDate") ? "/" : ""
                } ${meter.get("meterServiceEndDate")?.substring(0, 10)}`;
              meterStatus = meter.get("meterStatusText");
            }

            return (
              <Grid
                alignItems="flex-start"
                container
                key={i}
                style={{ padding: "5px 0" }}
              >
                <Grid item xs={5}>
                  <Link
                    to={`/members/${m.get("memberID")}/details`}
                    activeStyle={{
                      textDecoration: "none",
                      color: "black",
                      position: "abosolute",
                      right: 0,
                    }}
                    component="button"
                  >
                    {meterID}
                  </Link>
                </Grid>
                <Grid item xs={4}>
                  {meterDates}
                </Grid>
                <Grid item xs={3}>
                  {meterStatus}
                </Grid>
              </Grid>
            );
          })}
      </div>
    </List>
  );
};

RelatedMembers.propTypes = {
  members: PropTypes.shape({
    map: PropTypes.func,
  }),
  meters: PropTypes.shape({
    find: PropTypes.func,
  }),
};

class MemberDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: "",
      addFunds: "",
      applyRefund: "",
      nameEdit: false,
      emailEdit: false,
      phoneEdit: false,
      languageEdit: false,
      salesChannelEdit: false,
      secondaryNameEdit: false,
      secondaryEmailEdit: false,
      secondaryPhoneEdit: false,
      rechargeEdit: false,
      balanceEdit: false,
      billingAddressEdit: false,
      memberDatesEdit: false,
      disconnectTypeEdit: false,
      disconnectTypeEditManually: false,
      rejectDialog: false,
      cancelledDialog: false,
      lmiEdit: false,
      lmiTypeEdit: false,
      showModal: false,
      modalType: -1,
    };
    this.handleCloseUpdateAddress = this.handleCloseUpdateAddress.bind(this);
    this.handleOpenUpdateAddress = this.handleOpenUpdateAddress.bind(this);

    this.handleCloseUpdateMeterDates =
      this.handleCloseUpdateMeterDates.bind(this);
    this.handleOpenUpdateMeterDates =
      this.handleOpenUpdateMeterDates.bind(this);

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleModalConfirm = this.handleModalConfirm.bind(this);
    this.handleExemptionChange = this.handleExemptionChange.bind(this);

    this.handleRecharge = this.handleRecharge.bind(this);
    this.handleArchive = this.handleArchive.bind(this);
    this.handleAutopay = this.handleAutopay.bind(this);
    this.handleStatementMethod = this.handleStatementMethod.bind(this);
    this.handleRemoveMemberProduct = this.handleRemoveMemberProduct.bind(this);
    this.handleCloseDisconnectType = this.handleCloseDisconnectType.bind(this);
    this.handleOpenDisconnectType = this.handleOpenDisconnectType.bind(this);
    this.handleRejectDialog = this.handleRejectDialog.bind(this);
    this.handleCancelledDialog = this.handleCancelledDialog.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.userID !== this.props.userID) {
      const { onFetchCardList } = this.props;
      if (this.props.userID !== undefined)
        onFetchCardList(this.props.userID, this.props.params.memberId);
    }
  }
  componentWillMount() {
    const {
      member,
      onFetchUserIDWithFullMemberInfoNeeded,
      onfetchFullMemberInfoNeeded,
      requestTaxExcemptions,
      taxInfo,
      onFetchCardList,
      onFetchLMITypes,
      onFetchRequestTdsps,
    } = this.props;
    if (member.get("userID") === undefined) {
      onFetchUserIDWithFullMemberInfoNeeded(this.props.params.memberId);
    } else {
      onfetchFullMemberInfoNeeded(this.props.member);
    }

    if (this.props.userID !== undefined && this.props.cardList.size === 0) {
      onFetchCardList(this.props.userID, this.props.params.memberId);
    }

    if (this.props.member.get("service_provider") === "cs_meter") {
      onFetchLMITypes();
    }
    onFetchRequestTdsps()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.member !== this.member) {
      // set meter states only when it is not set
      nextProps.member.get("meters").forEach((meter, index) => {
        if (
          this.state[`startDateMeter_${index}`] === undefined ||
          nextProps.member.get("meters") !== this.props.member.get("meters")
        ) {
          this.state[`startDateMeter_${index}`] = meter.get("start_date");
        }
        if (
          this.state[`endDateMeter_${index}`] === undefined ||
          nextProps.member.get("meters") !== this.props.member.get("meters")
        ) {
          this.state[`endDateMeter_${index}`] = meter.get("end_date");
        }
        if (
          this.state[`disconnectTypeMeter_${index}`] === undefined ||
          nextProps.member.get("meters") !== this.props.member.get("meters")
        ) {
          this.state[`disconnectTypeMeter_${index}`] =
            meter.get("disconnect_type");
        }
        if (
          this.state[`lmi_${index}`] === undefined ||
          nextProps.member.get("meters") !== this.props.member.get("meters")
        ) {
          this.state[`lmi_${index}`] = meter.get("lmi");
        }
        if (
          this.state[`lmi_type_${index}`] === undefined ||
          nextProps.member.get("meters") !== this.props.member.get("meters")
        ) {
          this.state[`lmi_type_${index}`] = meter.get("lmi_type");
        }
        this.state[`skipNotification_${index}`] = true;
      });

      // set birthday
      this.state["birthday"] = nextProps.member.get("birthday")
        ? nextProps.member.get("birthday")
        : "";

      // set secondary birthday
      this.state["secondaryBirthday"] = nextProps.member.get(
        "secondary_account_holder"
      )
        ? nextProps.member.get("secondary_account_holder").get("birthday")
        : "";
      if (
        this.props.member.get("userID") !== undefined &&
        this.props.member.get("memberID") != undefined &&
        nextProps.params.memberId !== this.props.member.get("memberID")
      ) {
        this.props.onClearSelectedMember();
        this.props.onFetchUserIDWithFullMemberInfoNeeded(
          this.props.params.memberId
        );
      }
    }
  }

  componentWillUnmount() {
    const { onClearConfMsg } = this.props;
    onClearConfMsg();
  }

  handleOpenUpdateAddress() {
    this.setState({ billingAddressEdit: true });
  }

  handleCloseUpdateAddress() {
    this.setState({ billingAddressEdit: false });
  }

  handleOpenUpdateMeterDates() {
    this.setState({ memberDatesEdit: true });
  }

  handleCloseUpdateMeterDates() {
    this.setState({ memberDatesEdit: false });
  }

  handleOpenDisconnectType() {
    this.setState({ disconnectTypeEdit: true });
  }

  handleRejectDialog(status) {
    this.setState({ rejectDialog: status });
  }

  handleCancelledDialog(status) {
    this.setState({ cancelledDialog: status });
  }

  handleDisconnectTypeChange = (key) => (disconnectType) => {
    this.setState({ [key]: disconnectType });
  };

  handleLMIChange = (key) => (lmi) => {
    this.setState({ [key]: lmi });
    this.setState({ ["lmiEdit"]: false });
  };

  handleLMITypeChange = (key) => (type) => {
    this.setState({ [key]: type });
    this.setState({ ["lmiTypeEdit"]: false });
  };

  handleCloseDisconnectType() {
    this.setState({ disconnectTypeEdit: false });
  }

  handleOpenModal(modalType) {
    this.setState({ showModal: true, modalType });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }
  handleOpenModalForMeter(modalType, meterID) {
    this.setState({
      showModal: true,
      modalType,
      removeDateMeterID: meterID,
    });
  }
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDateChange = (name) => (value) => {
    const dateValue =
      value !== null && value !== ""
        ? `${moment(value).utcOffset(0).format("yyyy-MM-DD")}`
        : null;
    this.setState({
      [name]: dateValue,
    });
  };

  handleEndDateChange =
    (meterID, index, onMeterUpdate, startDate, endDate, notify) => (ev) => {
      // if end is set and different from start date ask user for disconnect type
      if (startDate !== null && endDate !== null) {
        if (startDate.isSame(endDate)) {
          let disconnectType = "cancel";
          onMeterUpdate(
            meterID,
            {
              start_date: startDate,
              end_date: endDate,
            },
            endDate,
            {
              disconnect_type: disconnectType,
              start_date: startDate,
              null_start_date: startDate === null,
              end_date: endDate,
              null_end_date: endDate === null,
              skip_meter_status_notification: notify,
            }
          );
          this.handleDateChange(`startDateMeter_${index}`)(startDate);
          this.handleDateChange(`endDateMeter_${index}`)(endDate);
          this.handleDisconnectTypeChange(`disconnectTypeMeter_${index}`)(
            disconnectType
          );
        } else {
          this.handleDateChange(`startDateMeter_${index}`)(startDate);
          this.handleDateChange(`endDateMeter_${index}`)(endDate);
          this.setState({ [`skipNotification_${index}`]: notify });
          this.handleOpenDisconnectType();
        }
      } else {
        // no need to ask for disconnect type, we just go ahead and update end date
        let disconnectType = this.state[`disconnectTypeMeter_${index}`];
        if (endDate === null) {
          this.handleDisconnectTypeChange(`disconnectTypeMeter_${index}`)("");
          disconnectType = "";
        }
        onMeterUpdate(
          meterID,
          {
            start_date: startDate,
            end_date: endDate,
          },
          endDate,
          {
            disconnect_type: disconnectType,
            start_date: startDate,
            null_start_date: startDate === null,
            end_date: endDate,
            null_end_date: endDate === null,
            skip_meter_status_notification: notify,
          }
        );

        // if end date is set to null, clear disconnect type in UI
        this.handleDateChange(`startDateMeter_${index}`)(startDate);
        this.handleDateChange(`endDateMeter_${index}`)(endDate);
        this.handleDisconnectTypeChange(`disconnectTypeMeter_${index}`)(
          disconnectType
        );
      }
    };

  handleRemoveMemberProduct = item => {
    this.props.onApplyRemoveMemberProduct({
      product_id: item.ID,
      start_date: item.start_date,
      author_email: this.props.adminUser.email,
      author_id: this.props.adminUser.userID,
      member_id: this.props.member.get("memberID"),
      user_id: this.props.member.get("userID"),
      body: item.body,
    })
  }

  sendPasswordResetLink = () => { };
  handleModalConfirm() {
    switch (this.state.modalType) {
      case modalTypes.SET_AUTO_PAY:
        this.props.onUpdateAutopay(
          this.props.params.memberId,
          !this.props.member.get("autopay")
        );
        break;
      case modalTypes.SET_STATEMENT_DELIVERY:
        this.props.onUpdateStatementDelivery(
          this.props.params.memberId,
          this.props.member.get("statement_delivery") === "email"
            ? "paper"
            : "email"
        );
        break;
      case modalTypes.ADD_FUND:
        this.props.onApplyAddFunds(
          this.props.params.memberId,
          "amount",
          this.state.addFunds
        );
        break;
      case modalTypes.REFUND:
        this.props.onApplyRefund(
          this.props.params.memberId,
          "amount_dollars",
          this.state.applyRefund
        );
        this.setState({ balanceEdit: false });
        break;
      case modalTypes.OPEN_ACCOUNT:
        this.props.onOpenAccount(this.props.params.memberId);
        break;
      case modalTypes.CLOSE_ACCOUNT:
        this.props.onCloseAccount(this.props.params.memberId);
        break;
      case modalTypes.DEACTIVATE_ACCOUNT:
        this.props.onRefundAndClose(this.props.params.memberId);
        break;
      case modalTypes.ENABLE_USER:
        this.props.onActivateUser(this.props.member.get("userID"));
        break;
      case modalTypes.DISABLE_USER:
        this.props.onDeActivateUser(this.props.member.get("userID"));
        break;
      case modalTypes.REMOVE_START_DATE:
        this.props.onMeterUpdate(
          this.state.removeDateMeterID,
          "null_start_date",
          true
        );
        break;
      case modalTypes.REMOVE_END_DATE:
        this.props.onMeterUpdate(
          this.state.removeDateMeterID,
          "null_end_date",
          true
        );
        break;
      case modalTypes.SET_METER_IS_CANCELLED:
        this.props.onMeterUpdate(
          this.props.member.get("meters").first().get("ID"),
          "is_cancelled",
          true
        );
        break;
      case modalTypes.OPEN_ARCHIVE:
      case modalTypes.CLOSE_ARCHIVE:
        this.props.onArchiveMember(
          this.props.params.memberId,
          !this.props.member.get("archive")
        );
        break;
      default:
        break;
    }
    this.handleCloseModal();
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.onHideSnackBar();
  };

  handleExemptionChange = (update, meterID, meters) => {
    return function (event) {
      update(meters, meterID, event.target.value);
    };
  };

  handleToggle = (name) => () => {
    const toggled = this.state[name];

    this.setState({
      [name]: !toggled,
    });
  };

  pressEnter = (update, id, property, value, handleToggle) => (ev) => {
    if (ev.key === "Enter") {
      if (
        update === this.props.onMeterUpdate &&
        value === "Invalid dateT00:00:00Z"
      ) {
        this.handleOpenModalForMeter(
          property === "start_date"
            ? modalTypes.REMOVE_START_DATE
            : modalTypes.REMOVE_END_DATE,
          id
        );
      } else if (update === this.props.onApplyAddFunds) {
        this.handleOpenModal(modalTypes.ADD_FUND);
      } else if (update === this.props.onApplyRefund) {
        this.handleOpenModal(modalTypes.onApplyRefund);
      } else if (update === this.props.onAccountUpdate) {
        update(id, property, { [property]: value });
      } else {
        const updateValue = value?.value ? value.value : value;
        const secondaryObject = value?.value ? value : undefined;
        update(id, property, updateValue, secondaryObject);
        handleToggle();
        ev.preventDefault();
      }
    }
  };

  handleSelectChange = (update, id, property, handleToggle) => (ev) => {
    const value = ev.target.value;

    update(id, property, value);
    handleToggle();
    ev.preventDefault();
  };

  formatBillingAddressJson = (address) => {
    return `${address.get("line1")} ${address.get("line2")}`;
  };

  renderModalText() {
    switch (this.state.modalType) {
      case modalTypes.SET_AUTO_PAY:
        return (
          <div>
            Are you sure you want to{" "}
            {!this.props.member.get("autopay") === false ? "disable" : "enable"}{" "}
            Auto Pay ?
          </div>
        );
      case modalTypes.SET_STATEMENT_DELIVERY:
        return (
          <div>
            Are you sure you want to update Statement Delivery to{" "}
            {this.props.member.get("statement_delivery") === "email"
              ? "paper"
              : "email"}
            ?
          </div>
        );
      case modalTypes.ADD_FUND:
        return (
          <div>
            Are you sure you want to add ${this.state.addFunds} to the
            member&#39;s account?
          </div>
        );
      case modalTypes.REFUND:
        return (
          <div>
            Are you sure you want to refund ${this.state.applyRefund} to the
            member&#39;s account?
          </div>
        );
      case modalTypes.OPEN_ACCOUNT:
        return (
          <div>Are you sure you want to open member&#39;s billing account?</div>
        );
      case modalTypes.CLOSE_ACCOUNT:
        return (
          <div>
            Are you sure you want to close member&#39;s billing account?
          </div>
        );
      case modalTypes.DEACTIVATE_ACCOUNT:
        return (
          <div>
            Are you sure you want to deactive member&#39;s billing account?
            Note: this will close the account and refund all the amount.
          </div>
        );
      case modalTypes.DISABLE_USER:
        return (
          <div>
            Are you sure you want to deactivate the user&#39;s login account?
            Note: this will prevent the user to login.
          </div>
        );
      case modalTypes.ENABLE_USER:
        return (
          <div>
            Are you sure you want to activate the user&#39;s login account?
            Note: this will enable the user to login.
          </div>
        );

      case modalTypes.REMOVE_START_DATE:
        return (
          <div>Are you sure you want to remove start date for this meter?</div>
        );
      case modalTypes.REMOVE_END_DATE:
        return (
          <div>Are you sure you want to remove end date for this meter?</div>
        );
      case modalTypes.SET_METER_IS_CANCELLED:
        return <div>Are you sure you want to cancel the meter?</div>;
      case modalTypes.OPEN_ARCHIVE:
        return <div>Are you sure you want to archive this member?</div>;
      case modalTypes.CLOSE_ARCHIVE:
        return <div>Are you sure you want to view this member?</div>;
      default:
        return "";
    }
  }

  renderLanguageOptions() {
    return Object.entries(languageOptions).map(([code, language]) => {
      return (
        <MenuItem key={code} value={code}>
          {language}
        </MenuItem>
      );
    });
  }

  handleRecharge() {
    !this.props.member.get("active")
      ? this.handleOpenModal(modalTypes.OPEN_ACCOUNT)
      : this.handleOpenModal(modalTypes.CLOSE_ACCOUNT);
  }

  handleArchive() {
    !this.props.member.get("archive")
      ? this.handleOpenModal(modalTypes.OPEN_ARCHIVE)
      : this.handleOpenModal(modalTypes.CLOSE_ARCHIVE);
  }

  handleAutopay() {
    this.handleOpenModal(modalTypes.SET_AUTO_PAY);
  }

  handleStatementMethod() {
    this.handleOpenModal(modalTypes.SET_STATEMENT_DELIVERY);
  }

  handleDisconnectType(index, meterID, disconnectType) {
    this.props.onMeterUpdate(meterID, "disconnect_type", disconnectType);
    this.handleDisconnectTypeChange(`disconnectTypeMeter_${index}`)(
      disconnectType
    );
  }

  submitLMIChange(index, meterID, lmi) {
    this.props.onCSMeterUpdate(meterID, "lmi", lmi);
    this.handleLMIChange(`lmi_${index}`)(lmi);
  }

  submitLMITypeChange(index, meterID, lmiType) {
    this.props.onCSMeterUpdate(meterID, "lmi_type", lmiType);
    this.handleLMITypeChange(`lmi_type_${index}`)(lmiType);
  }

  submitSalesChannel(memberID, salesChannel) {
    const enrollment = this.props.member.get("enrollment")
    this.props.onCreateSalesChannelMember(
      memberID, 
      salesChannel, 
      enrollment ? enrollment.get("enrollment_type") : "",
      enrollment ? enrollment.get("id") : ""
    );
  }

  renderLMIType(index) {
    const value = this.props.member
      .get("lmi_types")
      .filter((x) => this.state[`lmi_type_${index}`] === x.get("id"))
      .first();
    return value ? value.get("name") : "";
  }

  getFormattedDate(expectedDate) {
    if (expectedDate && expectedDate !== '' && moment.utc(expectedDate).format("MM/DD/YYYY") !== '01/01/0001') {
      return moment.utc(expectedDate).format("MM/DD/YYYY")
    }
    return null
  }

  render() {
    let meterAddr;
    const {
      member,
      adminUser,
      onApplyAddFunds,
      onMakeOneTimePayment,
      onMakeOneTimePaymentICheck,
      onMakeOneTimePaymentStripe,
      onApplyRefund,
      onUserUpdate,
      onMemberUpdate,
      onArchiveMember,
      onChangeExemptionCode,
      onMeterUpdate,
      onAccountUpdate,
      classes,
      onCaptureDeposit,
    } = this.props;

    const userID = member.get("userID");
    const allMetersDisconnected =
      member.get("service_provider") == "proton_meter"
        ? member
          .get("meters")
          .every((m) => m.get("status").get("status") === "disconnected")
        : null;

    if (member.get("meters") && member.get("meters").first()) {
      if (member.get("service_provider") == "proton_meter") {
        meterAddr = (
          <span>
            {member.get("meters").first().get("service_address").get("lineOne")}{" "}
            {member.get("meters").first().get("service_address").get("city")}
            {", "}
            {member
              .get("meters")
              .first()
              .get("service_address")
              .get("state")}{" "}
            {member
              .get("meters")
              .first()
              .get("service_address")
              .get("postalCode")}{" "}
            {member.get("meters").first().get("service_address").get("country")}{" "}
          </span>
        );
      } else if (member.get("service_provider") == "cs_meter") {
        meterAddr = (
          <span>
            {member
              .get("meters")
              .first()
              .get("meta_data")
              .get("address")
              .get("line1")}{" "}
            {member
              .get("meters")
              .first()
              .get("meta_data")
              .get("address")
              .get("city")}
            {", "}
            {member
              .get("meters")
              .first()
              .get("meta_data")
              .get("address")
              .get("state")}{" "}
            {member
              .get("meters")
              .first()
              .get("meta_data")
              .get("address")
              .get("postalCode")}{" "}
            {member
              .get("meters")
              .first()
              .get("meta_data")
              .get("address")
              .get("country")}{" "}
          </span>
        );
      } else if (member.get("service_provider") == "eci_meter") {
        meterAddr = (
          <span>
            {member.get("meters").first().get("serviceAddress").get("line1")}{" "}
            {member.get("meters").first().get("serviceAddress").get("city")}
            {", "}
            {member
              .get("meters")
              .first()
              .get("serviceAddress")
              .get("state")}{" "}
            {member
              .get("meters")
              .first()
              .get("serviceAddress")
              .get("postalCode")}{" "}
          </span>
        );
      }
    } else {
      meterAddr = <span />;
    }

    return (
      <Elements>
        <form noValidate autoComplete="off">
          <Modal
            isOpen={member.get("isFetching")}
            contentLabel="Minimal Modal Example"
            style={modalStyle.loadingModal}
            ariaHideApp={false}
          >
            <ClipLoader
              sizeUnit="px"
              size={150}
              color="#ED0874"
              loading={member.get("isFetching")}
            />
          </Modal>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.props.taxInfo.showSnackBar}
            onClose={this.handleClose}
            className={
              this.props.taxInfo.successMessage
                ? confirmationSuccess
                : confirmationError
            }
            message={
              this.props.taxInfo.successMessage
                ? this.props.taxInfo.successMessage
                : this.props.taxInfo.errorMessage
            }
          />
          <Modal
            isOpen={this.state.showModal}
            style={modalStyle.modal}
            ariaHideApp={false}
          >
            {this.renderModalText()}
            <Button
              style={modalStyle.negativeButton}
              onClick={this.handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              style={modalStyle.positiveButton}
              onClick={this.handleModalConfirm}
            >
              Confirm
            </Button>
          </Modal>
          <Grid alignItems="flex-start" container>
            {/* Left coulumn */}
            <List>
              <h4
                style={{
                  marginLeft: "25px",
                  display: "inline-block",
                }}
              >
                Primary Account Holder
              </h4>
              {member.get("meterName") && (
                <h6
                  style={{
                    marginLeft: "15px",
                    display: "inline-block",
                  }}
                >
                  (Meter: {member.get("meterName")})
                </h6>
              )}
              {/* Primary Account Holder */}
              <ListItem divider>
                <ListItemText primary="Name: " />
                {this.state.nameEdit ? (
                  <div>
                    <TextField
                      autoFocus
                      label="First Name"
                      id="firstName"
                      defaultValue={member.get("first_name")}
                      onChange={this.handleChange("firstName")}
                      onKeyPress={this.pressEnter(
                        onUserUpdate,
                        userID,
                        "first_name",
                        this.state.firstName,
                        this.handleToggle("nameEdit")
                      )}
                      value={this.state.firstName}
                      type="string"
                    />
                    <TextField
                      label="Last Name"
                      id="lastName"
                      defaultValue={member.get("last_name")}
                      onChange={this.handleChange("lastName")}
                      onKeyPress={this.pressEnter(
                        onUserUpdate,
                        userID,
                        "last_name",
                        this.state.lastName,
                        this.handleToggle("nameEdit")
                      )}
                      value={this.state.lastName}
                      type="string"
                    />
                  </div>
                ) : (
                  `${member.get("first_name")} ${member.get("last_name")}`
                )}
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Edit"
                    onClick={this.handleToggle("nameEdit")}
                  >
                    <Edit style={{ marginLeft: "20px" }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Email: " />
                {this.state.emailEdit ? (
                  <div>
                    <ListItem divider>
                      <TextField
                        autoFocus
                        label="Email: "
                        id="email"
                        defaultValue={member.get("email")}
                        onChange={this.handleChange("email")}
                        onKeyPress={this.pressEnter(
                          onUserUpdate,
                          userID,
                          "email",
                          this.state.email,
                          this.handleToggle("emailEdit")
                        )}
                        value={this.state.email}
                        type="string"
                      />
                    </ListItem>
                  </div>
                ) : (
                  `${member.get("email")}`
                )}
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Edit"
                    onClick={this.handleToggle("emailEdit")}
                  >
                    <Edit style={{ marginLeft: "20px" }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Phone: " />
                {this.state.phoneEdit ? (
                  <div>
                    <ListItem divider>
                      <TextField
                        autoFocus
                        label="Phone: "
                        id="phone"
                        defaultValue={member.get("phone")}
                        onChange={this.handleChange("phone")}
                        onKeyPress={this.pressEnter(
                          onUserUpdate,
                          userID,
                          "phone",
                          this.state.phone,
                          this.handleToggle("phoneEdit")
                        )}
                        value={this.state.phone}
                        type="string"
                      />
                    </ListItem>
                  </div>
                ) : (
                  `${member.get("phone")}`
                )}
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Edit"
                    onClick={this.handleToggle("phoneEdit")}
                  >
                    <Edit style={{ marginLeft: "20px" }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Birthday: " />
                {this.state.birthdayEdit ? (
                  <div>
                    <ListItem divider>
                      <KeyboardDatePicker
                        margin="normal"
                        format="MM/dd/yyyy"
                        placeholder="mm/dd/yyyy"
                        value={
                          (this.state.birthday &&
                            moment.utc(this.state.birthday).format("l LT")) ||
                          null
                        }
                        onChange={this.handleDateChange(`birthday`)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        onKeyPress={this.pressEnter(
                          onUserUpdate,
                          userID,
                          "birthday",
                          this.state.birthday,
                          this.handleToggle("birthdayEdit")
                        )}
                      />
                    </ListItem>
                  </div>
                ) : (
                  <Moment tz="Greenwich" format="MM/DD/YYYY">
                    {member.get("birthday")}
                  </Moment>
                )}
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Edit"
                    onClick={this.handleToggle("birthdayEdit")}
                  >
                    <Edit style={{ marginLeft: "20px" }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Language: " />
                {this.state.languageEdit ? (
                  <div>
                    <ListItem divider>
                      <Select
                        onChange={this.handleSelectChange(
                          onUserUpdate,
                          userID,
                          "language",
                          this.handleToggle("languageEdit")
                        )}
                        id="language"
                        defaultValue={
                          member.get("language") in languageOptions
                            ? member.get("language")
                            : "en"
                        }
                      >
                        {this.renderLanguageOptions()}
                      </Select>
                    </ListItem>
                  </div>
                ) : (
                  `${languageOptions[member.get("language")]}`
                )}
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Edit"
                    onClick={this.handleToggle("languageEdit")}
                  >
                    <Edit style={{ marginLeft: "15px" }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Enrollment Consent to Agreement: " />
                <Moment format="MM/DD/YYYY HH:MM:SS">
                  {member.get("created_date")}
                </Moment>{" "}
                UTC
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Enrollment Confirmation #: " />
                <Moment tz="America/Los_Angeles" format="YYYYMMDDHHMMSS">
                  {member.get("created_date")}
                </Moment>
                {member.get("userID")}
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Account Number: " />
                {member.get("account_number")}
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Customer Number: " />
                {member.get("userID")}
              </ListItem>
              <ListItem divider>
                <ListItemText primary="MemberID: " />
                {member.get("memberID")}
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Critical Care / Chronic Condition: " />
                {member.get("critical_care") ? "yes" : "no"}
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Bill To: " />
                {this.state.billToNameEdit ? (
                  <div>
                    <ListItem divider>
                      <TextField
                        autoFocus
                        label="Bill To: "
                        id="billNameTo"
                        defaultValue={member.get("bill_to_name")}
                        onChange={this.handleChange("billNameTo")}
                        onKeyPress={this.pressEnter(
                          onAccountUpdate,
                          member.get("accountID"),
                          "bill_to_name",
                          this.state.billNameTo,
                          this.handleToggle("billToNameEdit")
                        )}
                        value={this.state.billNameTo}
                        type="string"
                      />
                    </ListItem>
                  </div>
                ) : (
                  `${member.get("bill_to_name")}`
                )}
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Edit"
                    onClick={this.handleToggle("billToNameEdit")}
                  >
                    <Edit style={{ marginLeft: "20px" }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Billing Address: " />
                <ListItemSecondaryAction>
                  {member.get("billing_address")
                    ? this.formatBillingAddressJson(
                      member.get("billing_address")
                    )
                    : ""}
                  <IconButton
                    aria-label="Billing Address Edit"
                    onClick={this.handleToggle("billingAddressEdit")}
                  >
                    <Edit />
                  </IconButton>
                </ListItemSecondaryAction>
                <Dialog
                  className={classes.dialog}
                  onClose={this.handleCloseUpdateAddress}
                  aria-labelledby="customized-dialog-title"
                  open={this.state.billingAddressEdit}
                >
                  <UpdateBillingAddress
                    closePopup={this.handleCloseUpdateAddress}
                  />
                </Dialog>
              </ListItem>

              {/* Temporarily hidden GX-186 */}
              {/* 
              <ListItem divider>
                <ListItemText primary="Recharge Amt: " />
                {this.state.rechargeEdit ? (
                  <div>
                    <ListItem divider>
                      <TextField
                        autoFocus
                        label="Recharge Amount"
                        id="rechargeAmount"
                        defaultValue={member.get("recharge_dollars")}
                        onChange={this.handleChange("rechargeAmount")}
                        onKeyPress={this.pressEnter(
                          onMemberUpdate,
                          this.props.params.memberId,
                          "recharge_dollars",
                          this.state.rechargeAmount,
                          this.handleToggle("rechargeEdit")
                        )}
                        value={this.state.rechargeAmount}
                        type="string"
                      />
                    </ListItem>
                  </div>
                ) : (
                  `$${member.get("recharge_dollars")}`
                )}
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Edit"
                    onClick={this.handleToggle("rechargeEdit")}
                  >
                    <Edit style={{ marginLeft: "20px" }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem> */}
              <ListItem divider>
                <ListItemText primary="Meter Address: " />
                {meterAddr}
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Meter Provider: " />
                {member.get("service_provider")}
              </ListItem>
              <List>
                {member.get("meters").map((meter, index) => {
                  if (member.get("service_provider") === "proton_meter") {
                    return (
                      <div key={index}>
                        <List>
                          {/* Temporarily hidden GX-186 */}
                          {/* <ListItem>
                            <ListItemText primary="Est. Recharge Date: " />
                            <Moment
                              tz={meter
                                .get("service_address")
                                .get("time_zone_name")}
                              format="MM/DD/YYYY"
                            >
                              {member.get("est_top_off_date")}
                            </Moment>
                          </ListItem> */}
                          <ListItem divider>
                            <ListItemText primary="Tax Exemption" />
                            {this.props.taxInfo.exemptionList !== null &&
                              this.props.taxInfo.exemptionList.length > 0 ? (
                              <FormControl style={{ width: "200px" }}>
                                <InputLabel htmlFor="tax-exemption-code">
                                  Exemption Code
                                </InputLabel>
                                <Select
                                  value={
                                    meter
                                      .get("meta_data")
                                      .get("tax_exemption_code") || ""
                                  }
                                  onChange={this.handleExemptionChange(
                                    onChangeExemptionCode,
                                    meter.get("ID"),
                                    member.get("meters")
                                  )}
                                  inputProps={{
                                    name: "exemptionCode",
                                    id: "tax-exemption-code",
                                  }}
                                  id="exemptionCode"
                                >
                                  {this.props.taxInfo.exemptionList.map(
                                    (excemption, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={excemption.code}
                                        >
                                          {excemption.code} -{" "}
                                          {excemption.description}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                            ) : (
                              <div>Unavailable - (Try to reload)</div>
                            )}
                          </ListItem>

                          <ListItem divider>
                            <ListItemText primary="Enrollment completed: " />
                            <Moment format="MM/DD/YYYY HH:MM:SS Z">
                              {meter.get("enrollment_date")}
                            </Moment>
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Consent to Critical Care / Chronic Condition: " />
                            <Moment format="MM/DD/YYYY HH:MM:SS Z">
                              {meter.get("enrollment_date")}
                            </Moment>
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Meter Identifier: " />
                            {meter.get("meterIdentifier")}
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="ID: " />
                            {meter.get("ID")}
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Meter Status: " />
                            {meter.get("status").get("status")}
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Load Zone: " />
                            {meter.get("settlement_point")}
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Meter Type: " />
                            {meter.get("tdsp_ams_indicator")}
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Switch Hold: " />
                            {meter.get("switch_hold") ? "True" : "False"}
                          </ListItem>
                          {
                            HAS_CSA_SERVICE &&
                            <>
                              <ListItem divider>
                                <ListItemText primary="On CSA: " />
                                {meter.get("on_csa") ? "True" : "False"}
                              </ListItem>
                              <ListItem divider>
                                <ListItemText primary="CSA Effective Date: " />
                                {meter.get("csa_effective_date")}
                              </ListItem>
                            </>
                          }
                          <ListItem divider>
                            <ListItemText primary="Requested Start Date: " />
                            {meter.get("requested_start_date")}
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Start Date: " />
                            <ListItemSecondaryAction>
                              {(this.state[`startDateMeter_${index}`] &&
                                moment
                                  .utc(this.state[`startDateMeter_${index}`])
                                  .format("MM/DD/YYYY")) ||
                                null}
                              <IconButton
                                aria-label="Member Dates Edit"
                                onClick={this.handleToggle("memberDatesEdit")}
                              >
                                <Edit />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="End Date: " />
                            <ListItemSecondaryAction>
                              {(this.state[`endDateMeter_${index}`] &&
                                moment
                                  .utc(this.state[`endDateMeter_${index}`])
                                  .format("MM/DD/YYYY")) ||
                                null}
                              <IconButton
                                aria-label="Member Dates Edit"
                                onClick={this.handleToggle("memberDatesEdit")}
                              >
                                <Edit />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Dialog
                            className={classes.dialog}
                            onClose={this.handleCloseUpdateMeterDates}
                            aria-labelledby="customized-dialog-title"
                            open={this.state.memberDatesEdit}
                          >
                            <UpdateMeterDates
                              meter={meter}
                              onMeterUpdate={onMeterUpdate}
                              closePopup={this.handleCloseUpdateMeterDates}
                              handleChange={this.handleEndDateChange}
                              dates={{
                                index,
                                startDate:
                                  this.state[`startDateMeter_${index}`],
                                endDate: this.state[`endDateMeter_${index}`],
                                skipNotification:
                                  this.state[`skipNotification_${index}`],
                              }}
                            />
                          </Dialog>
                          <ListItem divider>
                            <ListItemText primary="Customer Type: " />
                            {meter.get("polr_customer_class")}
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="TDSP: " />
                            {meter.get("duns_number")}
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Enrollment Type (Switch / Move-in): " />
                            {meter.get("enrollMode")}
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Disconnect Type: " />
                            {this.state.disconnectTypeEditManually ? (
                              <div>
                                <ListItem divider>
                                  <FormControl style={{ width: "200px" }}>
                                    <InputLabel htmlFor="disconnect-type">
                                      Disconnect Type
                                    </InputLabel>
                                    <Select
                                      value={
                                        this.state[
                                        `disconnectTypeMeter_${index}`
                                        ]
                                      }
                                      id="selectDisconnectType"
                                      onChange={(event) => {
                                        this.handleDisconnectType(
                                          index,
                                          meter.get("ID"),
                                          event.target.value
                                        );
                                      }}
                                    >
                                      {["", "cancel", "switch", "move"].map(
                                        (value, index) => {
                                          return (
                                            <MenuItem key={index} value={value}>
                                              {value}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                </ListItem>
                              </div>
                            ) : (
                              <div style={{ marginRight: "50px" }}>
                                {this.state[`disconnectTypeMeter_${index}`]}
                              </div>
                            )}
                            <ListItemSecondaryAction>
                              <IconButton
                                aria-label="Edit"
                                onClick={this.handleToggle(
                                  "disconnectTypeEditManually"
                                )}
                              >
                                <Edit style={{ marginLeft: "20px" }} />
                              </IconButton>
                            </ListItemSecondaryAction>
                            <Dialog
                              className={classes.dialog}
                              onClose={this.handleCloseDisconnectType}
                              aria-labelledby="customized-dialog-title"
                              open={this.state.disconnectTypeEdit}
                            >
                              <SetDiconnectType
                                meterID={meter.get("ID")}
                                onMeterUpdate={onMeterUpdate}
                                startDateMeter={`${moment(
                                  this.state[`startDateMeter_${index}`]
                                ).format("yyyy-MM-DD")}T00:00:00Z`}
                                endDateMeter={`${moment(
                                  this.state[`endDateMeter_${index}`]
                                ).format("yyyy-MM-DD")}T00:00:00Z`}
                                closePopup={this.handleCloseDisconnectType}
                                handleDisconnectTypeChange={this.handleDisconnectTypeChange(
                                  `disconnectTypeMeter_${index}`
                                )}
                                skipNotification={
                                  this.state[`skipNotification_${index}`]
                                }
                              />
                            </Dialog>
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Sales Channel: " />
                            {this.state.salesChannelEdit ? (
                              <div>
                                <ListItem divider style={{ marginRight: "-10px" }}>
                                  <Select
                                    onChange={(event) => {
                                      this.submitSalesChannel(
                                        member.get("memberID"),
                                        event.target.value
                                      );
                                    }}
                                    id="salesChannel"
                                    defaultValue={
                                      member.get("salesChannel") ?
                                        member.get("salesChannel").get("channel_id") :
                                        null
                                    }
                                  >
                                    {
                                      this.props.salesChannel.channels.map((value, index) => {
                                        return (
                                          <MenuItem key={index} value={value.id}>
                                            {value.name}
                                          </MenuItem>
                                        );
                                      })
                                    }
                                  </Select>
                                </ListItem>
                              </div>
                            ) : (
                              <div>
                                {
                                  member.get("salesChannel") && this.props.salesChannel && 
                                  this.props.salesChannel.channels.find(x => member.get("salesChannel").get("channel_id") === x.id) ?
                                    this.props.salesChannel.channels.find(x => member.get("salesChannel").get("channel_id") === x.id).name :
                                    null
                                }
                              </div>
                            )}
                            <IconButton
                              aria-label="Edit"
                              onClick={this.handleToggle("salesChannelEdit")}
                            >
                              <Edit />
                            </IconButton>
                          </ListItem>
                        </List>
                      </div>
                    );
                  } else if (member.get("service_provider") === "cs_meter") {
                    return (
                      <div key={index}>
                        <List>
                          <ListItem divider>
                            <ListItemText primary="LMI: " />
                            {this.state.lmiEdit ? (
                              <div>
                                <ListItem divider>
                                  <FormControl style={{ width: "75" }}>
                                    <InputLabel htmlFor="lmi">LMI</InputLabel>
                                    <Select
                                      value={this.state[`lmi_${index}`]}
                                      id="selectLmi"
                                      onChange={(event) => {
                                        this.submitLMIChange(
                                          index,
                                          meter.get("id"),
                                          event.target.value
                                        );
                                      }}
                                    >
                                      {["no", "review", "yes"].map(
                                        (value, index) => {
                                          return (
                                            <MenuItem key={index} value={value}>
                                              {value.toUpperCase()}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                </ListItem>
                              </div>
                            ) : (
                              <div style={{ marginRight: "5px" }}>
                                {(this.state[`lmi_${index}`]
                                  ? this.state[`lmi_${index}`]
                                  : ""
                                ).toUpperCase()}
                              </div>
                            )}
                            <ListItemSecondaryAction>
                              <IconButton
                                aria-label="Edit"
                                onClick={this.handleToggle("lmiEdit")}
                              >
                                <Edit style={{ marginLeft: "20px" }} />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="LMI Type: " />
                            {this.state.lmiTypeEdit ? (
                              <div>
                                <ListItem divider>
                                  <Select
                                    onChange={(event) => {
                                      this.submitLMITypeChange(
                                        index,
                                        meter.get("id"),
                                        event.target.value
                                      );
                                    }}
                                    id="selectLmiType"
                                    value={this.state[`lmi_type_${index}`]}
                                  >
                                    {member
                                      .get("lmi_types")
                                      .filter((x) =>
                                        x
                                          .get("eligible_states")
                                          .includes(
                                            member.get("address").get("state")
                                          )
                                      )
                                      .map((item, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={item.get("id")}
                                          >
                                            {item.get("name")}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                </ListItem>
                              </div>
                            ) : (
                              <div
                                style={{ marginRight: "5px", width: "150px" }}
                              >
                                {this.renderLMIType(index)}
                              </div>
                            )}
                            <ListItemSecondaryAction>
                              <IconButton
                                aria-label="Edit"
                                onClick={this.handleToggle("lmiTypeEdit")}
                              >
                                <Edit style={{ marginLeft: "15px" }} />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Meter Identifier: " />
                            {this.state.meterIdentifierEdit ? (
                              <div>
                                <ListItem divider>
                                  <TextField
                                    autoFocus
                                    label="Meter Identifier: "
                                    id="meterIdentifier"
                                    defaultValue={this.state.meterIdentifier ? this.state.meterIdentifier : meter.get("meter_identifier")}
                                    onChange={this.handleChange("meterIdentifier")}
                                    onKeyPress={e => {
                                      if (e.key === "Enter") {
                                        let tdsp = this.props.tdsps.filter(x => x.duns_number === meter.get("tdsp_duns"))
                                        if (tdsp.length > 0) {
                                          const value = this.state.meterIdentifier ? this.state.meterIdentifier : meter.get("meter_identifier")
                                          if (tdsp[0]?.config?.meterIdentifier?.regex) {
                                            const regex = new RegExp(tdsp[0].config.meterIdentifier.regex)
                                            if (regex.test(value)) {
                                              this.props.onCSMeterUpdate(meter.get("id"), "meter_identifier", value);
                                              e.preventDefault()
                                            } else {
                                              e.preventDefault()
                                              this.props.onCSMeterUpdate(meter.get("id"), "meter_identifier", null, { error: "Invalid meter Identifier" });
                                            }
                                          } else {
                                            this.props.onCSMeterUpdate(meter.get("id"), "meter_identifier", value);
                                            e.preventDefault()
                                          }
                                        } else {
                                          e.preventDefault()
                                          this.props.onCSMeterUpdate(meter.get("id"), "meter_identifier", null, { error: "Invalid TDSP" });
                                        }
                                      }
                                    }}
                                    value={this.state.meterIdentifier}
                                    type="string"
                                  />
                                </ListItem>
                              </div>
                            ) : (
                              `${this.state.meterIdentifier ? this.state.meterIdentifier : meter.get("meter_identifier")}`
                            )}
                            {
                              meter.get("status") !== "allocated" &&
                              <ListItemSecondaryAction>
                                <IconButton
                                  aria-label="Edit"
                                  onClick={this.handleToggle("meterIdentifierEdit")}
                                >
                                  <Edit style={{ marginLeft: "20px" }} />
                                </IconButton>
                              </ListItemSecondaryAction>
                            }
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Meter Identifier 2: " />
                            {this.state.meterIdentifier2Edit ? (
                              <div>
                                <ListItem divider>
                                  <TextField
                                    autoFocus
                                    label="Meter Identifier 2: "
                                    id="meterIdentifier2"
                                    defaultValue={this.state.meterIdentifier2 ? this.state.meterIdentifier2 : meter.get("meter_identifier2")}
                                    onChange={this.handleChange("meterIdentifier2")}
                                    onKeyPress={e => {
                                      if (e.key === "Enter") {
                                        let tdsp = this.props.tdsps.filter(x => x.duns_number === meter.get("tdsp_duns"))
                                        if (tdsp.length > 0) {
                                          const value = this.state.meterIdentifier2 ? this.state.meterIdentifier2 : meter.get("meter_identifier2")
                                          if (tdsp[0]?.config?.meterIdentifier2?.regex) {
                                            const regex = new RegExp(tdsp[0]['config']['meterIdentifier2']['regex'])
                                            if (regex.test(value)) {
                                              this.props.onCSMeterUpdate(meter.get("id"), "meter_identifier2", value);
                                              e.preventDefault()
                                            } else {
                                              e.preventDefault()
                                              this.props.onCSMeterUpdate(meter.get("id"), "meter_identifier2", null, { error: "Invalid meter Identifier 2" });
                                            }
                                          } else {
                                            this.props.onCSMeterUpdate(meter.get("id"), "meter_identifier2", value);
                                            e.preventDefault()
                                          }
                                        } else {
                                          e.preventDefault()
                                          this.props.onCSMeterUpdate(meter.get("id"), "meter_identifier2", null, { error: "Invalid TDSP" });
                                        }
                                      }
                                    }}
                                    value={this.state.meterIdentifier2}
                                    type="string"
                                  />
                                </ListItem>
                              </div>
                            ) : (
                              `${this.state.meterIdentifier2 ? this.state.meterIdentifier2 : meter.get("meter_identifier2")}`
                            )}
                            {
                              meter.get("status") !== "allocated" &&
                              <ListItemSecondaryAction>
                                <IconButton
                                  aria-label="Edit"
                                  onClick={this.handleToggle("meterIdentifier2Edit")}
                                >
                                  <Edit style={{ marginLeft: "20px" }} />
                                </IconButton>
                              </ListItemSecondaryAction>
                            }
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Status: " />
                            {meter.get("status")}
                            {
                              ['rejected', 'new'].includes(meter.get('status')) &&
                              adminUser.permissions &&
                              adminUser.permissions.isCSMeterManager &&
                              <ListItemSecondaryAction>
                                <IconButton
                                  aria-label="Edit"
                                  onClick={() => this.handleRejectDialog(!this.state.rejectDialog)}
                                >
                                  <Edit style={{ marginLeft: "20px" }} />
                                </IconButton>
                              </ListItemSecondaryAction>
                            }
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Status Reason: " />
                            {meter.get("status_reason")}
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Start Date: " />
                            {this.getFormattedDate(meter.get("start_date"))}
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="End Date: " />
                            {this.getFormattedDate(meter.get("end_date"))}
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Transfer Date: " />
                            {this.getFormattedDate(meter.get("transfer_date"))}
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Cancellation Reason: " />
                            {meter.get("cancellation_reason")}
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Project Name: " />
                            {meter.get("project_name")}
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Annual Volume: " />
                            {meter.get("annual_kwh")} kwh
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Rate Code: " />
                            {meter.get("utility_rate_code")}
                          </ListItem>
                          <Dialog
                            className={classes.dialog}
                            onClose={() => this.handleRejectDialog(!this.state.rejectDialog)}
                            aria-labelledby="customized-dialog-title"
                            open={this.state.rejectDialog}
                          >
                            <MemberFixReject
                              meter={meter}
                              closePopup={() => this.handleRejectDialog(!this.state.rejectDialog)}
                              handleChange={body => {
                                this.props.onCSMeterBodyUpdate(meter.get('id'), body, member.get("memberID"))
                              }}
                            />
                          </Dialog>
                          <Dialog
                            className={classes.dialog}
                            onClose={() => this.handleCancelledDialog(!this.state.cancelledDialog)}
                            aria-labelledby="customized-dialog-title"
                            open={this.state.cancelledDialog}
                          >
                            <MemberCancelled
                              meter={meter}
                              closePopup={() => this.handleCancelledDialog(!this.state.cancelledDialog)}
                              handleChange={body => {
                                this.props.onCSMeterBodyUpdate(meter.get('id'), body, member.get("memberID"))
                              }}
                            />
                          </Dialog>
                        </List>
                      </div>
                    );
                  } else if (member.get("service_provider") === "eci_meter") {
                    return (
                      <div key={index}>
                        <List>
                          <ListItem divider>
                            <ListItemText primary="Meter Identifier: " />
                            {meter.get("meterIdentifier")}
                          </ListItem>
                          <ListItem divider>
                            <ListItemText primary="Status: " />
                            {meter.get("meterStatusText")}
                          </ListItem>
                        </List>
                      </div>
                    );
                  }
                })}
                {member.get("service_provider") !== "eci_meter" && (<>
                  <ListItem divider>
                    <ListItemText primary="Billing Account ID: " />
                    {member.get("accountID")}
                  </ListItem>
                  {/* Temporarily hidden GX-186
                  <ListItem divider>
                                <ListItemText primary="Stripe Account ID: " />
                                {member.get("stripeAccountId")}
                              </ListItem> */}
                  {member.get("billing_method") === "postpay" && (
                    <ListItem divider>
                      <ListItemText primary="Auto Pay: " />
                      <InputSwitch
                        checked={member.get("autopay")}
                        onChange={this.handleAutopay}
                        disabled={
                          this.props.cardList.size >= 1 ? false : true
                        }
                      />
                    </ListItem>
                  )}
                  {member.get("statement_delivery") && (<ListItem divider>
                    <ListItemText primary="E Billing: " />
                    <InputSwitch
                      checked={
                        member.get("statement_delivery") === "email"
                      }
                      onChange={this.handleStatementMethod}
                    />
                  </ListItem>)}
                </>)}
                {member.get("meters")?.first() && member.get("service_provider") === "proton_meter" && (<>
                  <ListItem divider>
                    <ListItemText primary="Recharge Status: " />
                    <InputSwitch
                      checked={member.get("active")}
                      onChange={this.handleRecharge}
                    />
                  </ListItem>
                  <ListItem divider>
                    <ListItemText primary="Archive member: " />
                    <InputSwitch
                      checked={member.get("archive")}
                      onChange={this.handleArchive}
                    />
                  </ListItem>
                  <ListItem divider>
                    <ListItemText primary="User Login Status: " />
                    {member.get("userIsActive")
                      ? "Enabled"
                      : "Disabled"}
                  </ListItem>
                </>)}
              </List>
              {member.get("meters").first() &&
                !member.get("meters").first().get("start_date") &&
                member.get("meters").first().get("status") &&
                member.get("meters").first().get("service_provider") ===
                "proton_meter" &&
                member.get("meters").first().get("status").get("status") ==
                "pending_connection" && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ margin: "3px" }}
                    onClick={() =>
                      this.handleOpenModal(modalTypes.SET_METER_IS_CANCELLED)
                    }
                  >
                    MARK AS CANCELLED
                  </Button>
                )}
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "3px" }}
                onClick={() =>
                  this.handleOpenModal(modalTypes.DEACTIVATE_ACCOUNT)
                }
                disabled={!allMetersDisconnected}
              >
                Refund Account
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "3px" }}
                onClick={() => this.props.onResetPassword(member.get("email"))}
              >
                Reset Password
              </Button>
              {member.get("userIsActive") && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "3px" }}
                  onClick={() => this.handleOpenModal(modalTypes.DISABLE_USER)}
                >
                  DISABLE USER
                </Button>
              )}
              {!member.get("userIsActive") && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "3px" }}
                  onClick={() => this.handleOpenModal(modalTypes.ENABLE_USER)}
                >
                  ENABLE USER
                </Button>
              )}
              {
                member.get("meters").first() &&
                member.get("service_provider") === "cs_meter" &&
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "3px" }}
                  disabled={!(
                    ['rejected', 'new', 'pending'].includes(member.get("meters").first().get('status')) &&
                    (
                      member.get("meters").first().get('project_id') === undefined ||
                      member.get("meters").first().get('project_id') === null ||
                      member.get("meters").first().get('project_id') === 0
                    )
                  )}
                  onClick={() => this.handleCancelledDialog(!this.state.cancelledDialog)}
                >
                  Cancel account
                </Button>
              }
              {
                member.get("meters").first() &&
                adminUser.permissions &&
                adminUser.permissions.isCustomerImpersonate &&
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "3px" }}
                  onClick={() => {
                    const userID = this.props.member.get("userID")
                    const memberID = this.props.member.get("meters").first().get("memberID")
                    this.props.onFetchRedirectToCustomer(userID, memberID)
                  }}
                >
                  Open Account
                </Button>
              }
            </List>
            {/* Right coulumn */}
            <List>
              <h4 style={{ marginLeft: "25px" }}> Secondary Account Holder </h4>
              {/* Secondayr Account Holder */}
              <div style={{ marginLeft: "15px", marginBottom: "25px" }}>
                <ListItem divider>
                  <ListItemText primary="Name: " />
                  {this.state.secondaryNameEdit ? (
                    <div>
                      <TextField
                        autoFocus
                        label="Name"
                        id="secondaryName"
                        defaultValue={
                          member.get("secondary_account_holder")
                            ? member.get("secondary_account_holder").get("name")
                            : ""
                        }
                        onChange={this.handleChange("secondaryName")}
                        onKeyPress={this.pressEnter(
                          onMemberUpdate,
                          this.props.params.memberId,
                          "secondary_account_holder",
                          {
                            value: {
                              name: this.state.secondaryName,
                              email: member.get("secondary_account_holder")
                                ? member
                                  .get("secondary_account_holder")
                                  .get("email")
                                : "",
                              phone: member.get("secondary_account_holder")
                                ? member
                                  .get("secondary_account_holder")
                                  .get("phone")
                                : "",
                              birthday: member.get("secondary_account_holder")
                                ? member
                                  .get("secondary_account_holder")
                                  .get("birthday")
                                : "",
                            },
                            title: this.state.secondaryName,
                            property: "name",
                          },
                          this.handleToggle("secondaryNameEdit")
                        )}
                        value={this.state.secondaryName}
                        type="string"
                      />
                    </div>
                  ) : (
                    `${member.get("secondary_account_holder")
                      ? member.get("secondary_account_holder").get("name")
                      : ""
                    }`
                  )}
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label="Edit"
                      onClick={this.handleToggle("secondaryNameEdit")}
                    >
                      <Edit style={{ marginLeft: "20px" }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                  <ListItemText primary="Email: " />
                  {this.state.secondaryEmailEdit ? (
                    <div>
                      <ListItem divider>
                        <TextField
                          autoFocus
                          label="Email: "
                          id="secondaryEmail"
                          defaultValue={
                            member.get("secondary_account_holder")
                              ? member
                                .get("secondary_account_holder")
                                .get("email")
                              : ""
                          }
                          onChange={this.handleChange("secondaryEmail")}
                          onKeyPress={this.pressEnter(
                            onMemberUpdate,
                            this.props.params.memberId,
                            "secondary_account_holder",
                            {
                              value: {
                                name: member.get("secondary_account_holder")
                                  ? member
                                    .get("secondary_account_holder")
                                    .get("name")
                                  : "",
                                email: this.state.secondaryEmail,
                                phone: member.get("secondary_account_holder")
                                  ? member
                                    .get("secondary_account_holder")
                                    .get("phone")
                                  : "",
                                birthday: member.get("secondary_account_holder")
                                  ? member
                                    .get("secondary_account_holder")
                                    .get("birthday")
                                  : "",
                              },
                              title: this.state.secondaryEmail,
                              property: "email",
                            },
                            this.handleToggle("secondaryEmailEdit")
                          )}
                          value={this.state.secondaryEmail}
                          type="string"
                        />
                      </ListItem>
                    </div>
                  ) : (
                    `${member.get("secondary_account_holder")
                      ? member.get("secondary_account_holder").get("email")
                      : ""
                    }`
                  )}
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label="Edit"
                      onClick={this.handleToggle("secondaryEmailEdit")}
                    >
                      <Edit style={{ marginLeft: "20px" }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                  <ListItemText primary="Phone: " />
                  {this.state.secondaryPhoneEdit ? (
                    <div>
                      <ListItem divider>
                        <TextField
                          autoFocus
                          label="Phone: "
                          id="secondaryPhone"
                          defaultValue={
                            member.get("secondary_account_holder")
                              ? member
                                .get("secondary_account_holder")
                                .get("phone")
                              : ""
                          }
                          onChange={this.handleChange("secondaryPhone")}
                          onKeyPress={this.pressEnter(
                            onMemberUpdate,
                            this.props.params.memberId,
                            "secondary_account_holder",
                            {
                              value: {
                                name: member.get("secondary_account_holder")
                                  ? member
                                    .get("secondary_account_holder")
                                    .get("name")
                                  : "",
                                email: member.get("secondary_account_holder")
                                  ? member
                                    .get("secondary_account_holder")
                                    .get("email")
                                  : "",
                                phone: this.state.secondaryPhone,
                                birthday: member.get("secondary_account_holder")
                                  ? member
                                    .get("secondary_account_holder")
                                    .get("birthday")
                                  : "",
                              },
                              title: this.state.secondaryPhone,
                              property: "phone",
                            },
                            this.handleToggle("secondaryPhoneEdit")
                          )}
                          value={this.state.secondaryPhone}
                          type="string"
                        />
                      </ListItem>
                    </div>
                  ) : (
                    `${member.get("secondary_account_holder")
                      ? member.get("secondary_account_holder").get("phone")
                      : ""
                    }`
                  )}
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label="Edit"
                      onClick={this.handleToggle("secondaryPhoneEdit")}
                    >
                      <Edit style={{ marginLeft: "20px" }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                  <ListItemText primary="Birthday: " />
                  {this.state.secondaryBirthdayEdit ? (
                    <div>
                      <ListItem divider>
                        <KeyboardDatePicker
                          margin="normal"
                          format="MM/dd/yyyy"
                          placeholder="mm/dd/yyyy"
                          value={
                            (this.state.secondaryBirthday &&
                              moment
                                .utc(this.state.secondaryBirthday)
                                .format("l LT")) ||
                            null
                          }
                          onChange={this.handleDateChange(`secondaryBirthday`)}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          onKeyPress={this.pressEnter(
                            onMemberUpdate,
                            this.props.params.memberId,
                            "secondary_account_holder",
                            {
                              value: {
                                name: member.get("secondary_account_holder")
                                  ? member
                                    .get("secondary_account_holder")
                                    .get("name")
                                  : "",
                                email: member.get("secondary_account_holder")
                                  ? member
                                    .get("secondary_account_holder")
                                    .get("email")
                                  : "",
                                phone: member.get("secondary_account_holder")
                                  ? member
                                    .get("secondary_account_holder")
                                    .get("phone")
                                  : "",
                                birthday: this.state.secondaryBirthday,
                              },
                              title: this.state.secondaryBirthday,
                              property: "birthday",
                            },
                            this.handleToggle("secondaryBirthdayEdit")
                          )}
                        />
                      </ListItem>
                    </div>
                  ) : (
                    member
                      ?.get("secondary_account_holder")
                      ?.get("birthday") && (
                      <Moment tz="Greenwich" format="MM/DD/YYYY">
                        {member.get("secondary_account_holder")
                          ? member
                            .get("secondary_account_holder")
                            .get("birthday")
                            .split("T")[0]
                          : null}
                      </Moment>
                    )
                  )}
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label="Edit"
                      onClick={this.handleToggle("secondaryBirthdayEdit")}
                    >
                      <Edit style={{ marginLeft: "20px" }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </div>
              <MemberProduct
                handleEvent={this.handleRemoveMemberProduct}
                isTXMarket={member.get('address') ? member.get('address').get('state') === 'TX' : false}
                adminUser={adminUser}
              />
              <AccountBalance
                onApplyAddFunds={onApplyAddFunds}
                onMakeOneTimePayment={onMakeOneTimePayment}
                onMakeOneTimePaymentICheck={onMakeOneTimePaymentICheck}
                onMakeOneTimePaymentStripe={onMakeOneTimePaymentStripe}
                accountBalance={member.get("accountBalance")}
                transactions={member.get("transactions")}
                memberID={member.get("memberID")}
                userID={member.get("userID")}
                market={member.get("address").get("state")}
                accountID={member.get("accountID")}
                creditCardFee={member.get("credit_card_fee")}
              />
              <MemberDpp
                dpps={member.get("dpps")}
                accountBalance={member.get("accountBalance")}
                accountID={member.get("accountID")}
              />
              {member.get("service_provider") === "proton_meter" && (
                <MemberDeposit onCaptureDeposit={onCaptureDeposit} />
              )}
              {/* <CreditCardForm /> */}
              {member.get("accountID") && <AccountFlags />}
              {member.get("relatedMembers") && (
                <RelatedMembers
                  members={member.get("relatedMembers")}
                  meters={member.get("relatedMeters")}
                />
              )}
              <LevelBilling
                adminUser={adminUser}
                accountID={member.get("accountID")}
                levelBillings={member.get("level_billings")}
              />
            </List>
          </Grid>
        </form>
      </Elements>
    );
  }
}

MemberDetails.propTypes = {
  member: PropTypes.object,
  params: PropTypes.object.isRequired,
  onApplyRefund: PropTypes.func.isRequired,
  onApplyAddFunds: PropTypes.func.isRequired,
  onCloseAccount: PropTypes.func.isRequired,
  onOpenAccount: PropTypes.func.isRequired,
  onfetchFullMemberInfoNeeded: PropTypes.func.isRequired,
  onFetchUserIDWithFullMemberInfoNeeded: PropTypes.func.isRequired,
  onClearConfMsg: PropTypes.func.isRequired,
  onUserUpdate: PropTypes.func.isRequired,
  onMemberUpdate: PropTypes.func.isRequired,
  onRefundAndClose: PropTypes.func.isRequired,
  onChangeExemptionCode: PropTypes.func.isRequired,
  requestTaxExcemptions: PropTypes.func.isRequired,
  onAccountUpdates: PropTypes.func.isRequired,
};

MemberDetails.defaultProps = {
  member: {},
};

const mapStateToProps = (state) => ({
  member: state.selectedMember,
  adminUser: state.user,
  taxInfo: selectTaxInfoContainer(state)(state),
  userID: state.selectedMember.get("userID"),
  cardList: state.paymentCardList,
  salesChannel: state.salesChannel,
  tdsps: state.lookup.tdsps,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchCardList: (userID, memberID) =>
    dispatch(fetchCardList(userID, memberID)),
  onUpdateAutopay: (memId, autopay) => dispatch(updateAutopay(memId, autopay)),
  onUpdateStatementDelivery: (memId, statement_delivery) =>
    dispatch(updateStatementDelivery(memId, statement_delivery)),
  onApplyRemoveMemberProduct: (data) =>
    dispatch(applyRemoveMemberProduct(data)),
  onApplyRefund: (memId, property, value) =>
    dispatch(applyRefund(memId, property, value)),
  onApplyAddFunds: (memId, property, value) =>
    dispatch(applyAddFunds(memId, property, value)),
  onMakeOneTimePayment: (memId, property, value, payload) =>
    dispatch(makeOneTimePayment(memId, property, value, payload)),
  onMakeOneTimePaymentICheck: (memId, property, value, payload) =>
    dispatch(makeOneTimePaymentICheck(memId, property, value, payload)),
  onMakeOneTimePaymentStripe: (memId, property, value, payload) =>
    dispatch(makeOneTimePaymentStripe(memId, property, value, payload)),
  onCloseAccount: (memId) => dispatch(closeAccount(memId)),
  onRefundAndClose: (memId) => dispatch(refundAndCloseAccount(memId)),
  onOpenAccount: (memId) => dispatch(activateAccount(memId)),
  onfetchFullMemberInfoNeeded: (member) =>
    dispatch(fetchFullMemberInfoNeeded(member)),
  onFetchUserIDWithFullMemberInfoNeeded: (member) =>
    dispatch(fetchUserIDWithFullMemberInfoNeeded(member)),
  onClearConfMsg: () => dispatch(clearConfMsg()),
  onUserUpdate: (userId, property, value) =>
    dispatch(userUpdate(userId, property, value)),
  onMemberUpdate: (memberId, property, value, secondaryObject) =>
    dispatch(memberUpdate(memberId, property, value, secondaryObject)),
  onArchiveMember: (memberId, archive) =>
    dispatch(archiveMember(memberId, archive)),
  onChangeExemptionCode: (meters, meterId, exemptionCode) =>
    dispatch(changeExemptionCode(meters, meterId, exemptionCode)),
  onMeterUpdate: (meterID, property, value, moreProps) =>
    dispatch(meterUpdate(meterID, property, value, moreProps)),
  onCSMeterUpdate: (meterID, property, value, moreProps) =>
    dispatch(csmeterUpdate(meterID, property, value, moreProps)),
  onCSMeterBodyUpdate: (meterID, body, memberID, moreProps) =>
    dispatch(csmeterBodyUpdate(meterID, body, memberID, moreProps)),
  onResetPassword: (email) => dispatch(resetPassword(email)),
  onActivateUser: (userID) => dispatch(activateUser(userID)),
  onDeActivateUser: (userID) => dispatch(deActivateUser(userID)),
  onClearSelectedMember: () => {
    dispatch(clearSelectedMember());
  },
  requestTaxExcemptions: () => dispatch(requestTaxExcemptions()),
  onHideSnackBar: () => dispatch(hideTaxSnackBar()),
  onAccountUpdate: (accountID, key, updateDated) =>
    dispatch(updateAccount(accountID, key, updateDated)),
  onCaptureDeposit: (
    memberID,
    meterID,
    meterIdentifier,
    amount,
    enrollmentID
  ) =>
    dispatch(
      captureDeposit(memberID, meterID, meterIdentifier, amount, enrollmentID)
    ),
  onFetchLMITypes: () => dispatch(fetchLMITypes()),
  onFetchRequestTdsps: () => dispatch(requestTdsps()),
  onFetchRedirectToCustomer: (userID, memberID) => dispatch(redirectToCustomer(userID, memberID)),
  onCreateSalesChannelMember: (memberID, salesChannelId, enrollmentType, enrollmentId) =>
    dispatch(createSalesChannelMember(memberID, salesChannelId, enrollmentType, enrollmentId)),
  dispatch,
});

const ConnectedMemberDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberDetails);

export default withStyles(styles)(ConnectedMemberDetails);
