import * as C from "../../constants";

const initialState = {
  initialized: false,
  isProcessing: false,
  selectedIndex: -1,
  selectedEnrollment: {},
  enrollments: [],
  heldEnrollments: [],
  errorMessage: "",
  successMessage: "",
  showSnackBar: false,
  page: 0,
  pageHeld: 0,
  limit: 100,
  hasNext: false,
  hasNextHeld: false,
  total: 0,
  totalHeld: 0,
};

const loadEnrollment = (state, action) => {
  return {
    ...state,
    ...action,
  };
};

const loadMoreEnrollments = (state, action) => {
  if (action?.enrollments) {
    state.enrollments.push(...action.enrollments);
    return {
      ...state,
      page: action.page,
      hasNext: action.hasNext,
    };
  } else {
    state.heldEnrollments.push(...action.heldEnrollments);
    return {
      ...state,
      pageHeld: action.page,
      hasNextHeld: action.hasNext,
    };
  }
};

const closeSnackbar = (state) => {
  return {
    ...state,
    errorMessage: "",
    successMessage: "",
    showSnackBar: false,
  };
};

const setErrorMessage = (state, action) => {
  return {
    ...state,
    errorMessage: action.message,
    showSnackBar: true,
  };
};

const setSuccessMessage = (state, action) => {
  return {
    ...state,
    successMessage: action.message,
    showSnackBar: true,
  };
};

export default function enrollments(state = initialState, action) {
  switch (action.type) {
    case C.ENROLLMENT_SEARCH_TOGGLE_PROCESSING:
      return {
        ...state,
        isProcessing: !state.isProcessing,
      };
    case C.ENROLLMENT_SEARCH_SUCCESS:
      return loadEnrollment(state, action);
    case C.ENROLLMENT_SEARCH_LOAD_SUCCESS:
      return loadMoreEnrollments(state, action);
    case C.ENROLLMENT_SET_ERROR_MESSAGE:
      return setErrorMessage(state, action);
    case C.ENROLLMENT_SET_SUCCESS_MESSAGE:
      return setSuccessMessage(state, action);
    case C.ENROLLMENT_CLOSE_SNACKBAR:
      return closeSnackbar(state);
    default:
      return state;
  }
}
